
import './Header.css';
import image from "../images/IMG-20240723-WA0008.jpg";
import image1 from "../images/IMG-20240723-WA0005.jpg";
import image2 from "../images/IMG-20240723-WA0016.jpg";
import image3 from "../images/IMG-20240723-WA0017.jpg";
import image4 from '../images/IMG-20240723-WA0024.jpg';
import img13 from "../images/5565381.jpg"
import img14 from "../images/88209500.jpg"
import img15 from "../images/ghk-fashion-marquee-1582133108.png"
import group from "../Icons/Group.png"
import Frame from "../Icons/Frame.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMagnifyingGlass, faHeart, faUser, faCartPlus} from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Register from "../Register/Register"; 
import i18n from "../i18n";

function Header(props) {

    const navigate = useNavigate();
    const gotoOffers = () => {
        navigate('/Offers');
    };
    const gotoNewArriva = () => {
        navigate('/NewArriva');
    };
    const gotoFavorites = () => {
        navigate('/Favorites');
    };
    const gotoPurchasing = () => {
        navigate('/Purchasing');
    };


    // languages
    const languages = ['En', 'Ar'];
    const cantrys = [ ];

    const { t } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

    useEffect(() => {
        i18n.changeLanguage(language);
    
        // تعديل الـ direction والـ text-align بناءً على اللغة
        const body = document.body;
        if (language === 'ar') {
            body.style.direction = 'rtl';
            body.style.textAlign = 'right';
        } else {
            body.style.direction = 'ltr';
            body.style.textAlign = 'left';
        }
    }, [language]);




    const [icon, setIcon] = useState(false);
    const [text, setText] = useState(localStorage.getItem('text') || 'EN');
    function handelClick() {
        setIcon(!icon);
    }

    const [iconCantry, setIconCantry] = useState(false);
    const [imgCantry, setImgCantry] = useState(image4);
    function Clickcantry() {
        setIconCantry(!iconCantry);
    }

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
        
        const newText = lang === 'ar' ? 'AR' : 'EN'; // هنا بنحدد النص بناءً على اللغة
        setText(newText);
        localStorage.setItem('text', newText); // نحفظ النص الجديد في localStorage
    
        window.location.reload(); // عمل ريلود للصفحة بعد تغيير اللغة
    };

    const [showRegister, setShowRegister] = useState(false);
  
    // استرجاع البيانات من الـ localStorage
    const getLocalStorage = window.localStorage.getItem('UserInfo');
    const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
    const token = valueLocalStorage?.data?.token || null;
  
    // دالة الذهاب للبروفايل
    const gotoProfile = () => {
      if (token) {
        navigate('/Profile'); // لو مسجل هيروح للبروفايل
      } else {
        setShowRegister(true); // لو مش مسجل هيظهر شاشة التسجيل
      }
    };
  
    // دالة التسجيل
    const handleRegistrationSuccess = () => {
      setShowRegister(false);
      window.location.reload(); // إعادة تحميل الصفحة بعد التسجيل
    };
  
  

    return (
        <div className="Header">
                    {showRegister && <Register onSuccess={handleRegistrationSuccess} />}

            <div className="logo" >
                <div className='logoImg'>
                <Link to={"/"}>
                    <img src={group} alt="logo"/>
                </Link>
                </div>
                <div className='containers'>
                    <div className="cantry ">
                        <div className="slectList">
                            <img src={imgCantry} alt="selected country"/>
                            <i onClick={Clickcantry} className={`${iconCantry ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                        </div>
                        {iconCantry &&
                            <div className="lest">
                                {cantrys.map((e, i) => 
                                    <img src={e} key={i} alt={`country-${i}`} onClick={() => (setImgCantry(e), setIconCantry(false))} />
                                )}
                            </div>
                        }
                    </div>
                    <div className="language ">
                        <div className="slectList">
                            <p>{text}</p>
                            <i onClick={handelClick} className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                        </div>

                        {icon &&
                            <div className="lest">
                                {languages.map((e, i) => 
                                    <p key={i} onClick={() => (setText(e), setIcon(!icon), handleLanguageChange(e.toLowerCase()))}>{e}</p>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={`naveBar row justify-content-between align-items-center g-0 `}>
                <div className={`taps col-12 col-sm-12 col-md-6`}>
                    <div className='icon' onClick={gotoProfile}>
                        <img src={Frame}/>
                    </div>
                    <div className='div'>
                        <img src={img13}/>
                        <span className='font-size1'>{t('Trendy')}</span>
                    </div>
                    <div className='div' onClick={gotoNewArriva}>
                        <img src={img14}/>
                        <span className='font-size1'>{t("New arrival")}</span>
                    </div>
                    <div className='div' onClick={gotoOffers}>
                        <img src={img15}/>
                        <span className='font-size1'>{t('Offers')}</span>
                    </div>
                </div>
                <div className={`cearch col-12 col-sm-12 col-md-6`}>
                    <p className='border1'>
                    <div className={`inpute_search border`}>
                        <i className='magnifier '><FontAwesomeIcon icon={faMagnifyingGlass} /></i> 
                        <input type="text" className={`srch-input`} name="search"/>
                    </div>
                    </p>
                    <i className='i font-size2' onClick={gotoProfile}><FontAwesomeIcon icon={faUser} /></i>
                    <i className='i font-size2' onClick={gotoFavorites}><FontAwesomeIcon icon={faHeart} /></i>
                    <i className='i font-size2' onClick={gotoPurchasing}><FontAwesomeIcon icon={faCartPlus} /></i>


                </div>
            </div>
        </div>
    );
}

export default Header;