import "./Home.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Register from "../Register/Register";
import { useEffect, useState} from "react";
import img from "../images/IMG-20240723-WA0006.jpg";
import img2 from "../images/IMG-20240723-WA0008.jpg";
import img3 from "../images/IMG-20240723-WA0013.jpg";
import img4 from "../images/IMG-20240723-WA0018.jpg";
import img5 from "../images/IMG-20240723-WA0021.jpg";
import img6 from "../images/IMG-20240723-WA0022.jpg";
import img7 from "../images/IMG-20240723-WA0023.jpg";
import img8 from "../images/portfolio-2.jpg";
import img9 from "../images/IMG-20240723-WA0007.jpg";
import img10 from "../images/IMG-20240723-WA0008.jpg";
import img11 from "../images/IMG-20240723-WA0010.jpg";
import img12 from "../images/IMG-20240723-WA0025.jpg";
import img16 from "../images/Asset 1.png";
import img19 from "../images/Asset 10.png";
import img20 from "../images/Asset 11.png";
import img21 from "../images/Asset 12.png";
import img22 from "../images/Asset 2.png";
import img23 from "../images/Asset 22.png";
import img24 from "../images/Asset 23.png";
import img25 from "../images/Asset 3.png";
import img26 from "../images/Asset 5.png";
import img27 from "../images/Asset 6.png";
import img28 from "../images/Asset 7.png";
import img29 from "../images/Asset 9.png";
import img30 from "../images/Asset 16.png";
import img31 from "../images/Asset 19.png";
import img32 from "../images/Asset 20.png";
import icon9 from "../Icons/Icon-09.png";

import Loader from "../Loader";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { category, brands, recommended, offers, new_arrivals, best_of, store, influencer, ads, latest, addFavorite, favorites, product_reviews, add_wishlist, show_brand, category_data, ad_details } from "../Redux/API";
import { productDetails } from "../Redux/HomeSlice";

import { Link, NavLink } from "react-router-dom";
import { faCartPlus, faChevronLeft, faChevronRight, faExchangeAlt, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from "react-i18next";

function Home() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const gotoProductDetails = (product) => {
  //   dispatch(productDetails(product));  
  //   dispatch(product_reviews(product.id)); 
  //   console.log(product.id)
  //   navigate('/Product');  
  // };
  const gotoProductDetails = (product) => {
    if (!product || !product?.id) {
      return;
    }

    // إرسال البيانات إلى Redux
    dispatch(productDetails(product));
    dispatch(product_reviews(product?.id));

    // التنقل إلى صفحة التفاصيل
    navigate('/Product');
  };

  const gotoBrand = (id) => {
    console.log(id)
    navigate('/Brand');
    dispatch(show_brand({id}));

  };

  const gotoCategory = (aut) => {
    navigate('/SubSupCategory');
    dispatch(category_data({aut}));
  };

  
  // const gotoCategory = (aut) => {
  //   localStorage.setItem('categoryData', JSON.stringify(aut));

  //   navigate('/SubSupCategory');
    
  //   dispatch(category_data( aut ));
  // };
  

  const gotoMain = (id) => {
    navigate('/Main');
    dispatch(ad_details(id));

  };

  const loadingUnfollow = useSelector(state => state?.Data.loadingUnfollow);

  const handelAdd_wishlist = (id) => {
    dispatch(add_wishlist({id}));

  };


  const categorys = useSelector(state => state.Data.category);
  const loadingCategory = useSelector(state => state.Data.loadingCategory);
  const errorCategory = useSelector(state => state.Data.errorCategory);

  const brand = useSelector(state => state.Data.brands);
  const loadingBrands = useSelector(state => state.Data.loadingBrands);


  const recommendeds = useSelector(state => state.Data.recommended);
  const loadingRecommended = useSelector(state => state.Data.loadingRecommended);


  const offer = useSelector(state => state.Data.offers);
  const loadingOffers = useSelector(state => state.Data.loadingOffers);


  const new_arrival = useSelector(state => state.Data.new_arrivals);
  const loadingNew_arrivals = useSelector(state => state.Data.loadingNew_arrivals);

  const best_ofs = useSelector(state => state.Data.best_of);
  const loadingBest_of = useSelector(state => state.Data.loadingBest_of);

  const Stores = useSelector(state => state.Data.store);
  const loadingStore = useSelector(state => state.Data.loadingStore);

  const influencers = useSelector(state => state.Data.influencer);
  const loadingInfluencer = useSelector(state => state.Data.loadingInfluencer);

  const adss = useSelector(state => state.Data.ads); 
  const loadingAds = useSelector(state => state.Data.loadingAds); 

  const Latests = useSelector(state => state.Data.latest);
  const loadingLatest = useSelector(state => state.Data.loadingLatest);

  const favorite = useSelector(state => state.Data.favorites);

  const [currentIndex, setCurrentIndex] = useState(0);
  const images = adss?.data?.data || [];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // تغيير الصورة كل خمس ثواني

    return () => clearInterval(interval);
  }, [images.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  
  const imagess = brand?.data?.data?.brands || [];

  const imagess1 = recommendeds?.data?.data || [];

  const imagess2 = best_ofs?.data?.data || [];

  const images4 = offer?.data?.data || [];
  
  const images3 = new_arrival?.data?.data || [];

  const images6 = Stores?.data?.data || [];

  const images7 = influencers?.data?.data || [];
  console.log(images6)




  // const [visibleImages, setVisibleImages] = useState(imagess?.slice(0, 4)); // نبدأ بعرض 4 صور

  const [startIndex, setStartIndex] = useState(0);
  const [startIndex1, setStartIndex1] = useState(0);
  const [startIndex2, setStartIndex2] = useState(0);
  const [startIndex3, setStartIndex3] = useState(0);
  const [startIndex4, setStartIndex4] = useState(0);
  const [startIndex5, setStartIndex5] = useState(0);
  const [startIndex6, setStartIndex6] = useState(0);
  const [startIndex7, setStartIndex7] = useState(0);
  const [startIndex8, setStartIndex8] = useState(0);

  const handleNext = () => {
    if (startIndex + 5 < imagess?.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };


  const handleNext1 = () => {
    if (startIndex1 + 5 < imagess1?.length) {
      setStartIndex1(startIndex1 + 1);
    }
  };

  const handlePrev1 = () => {
    if (startIndex1 > 0) {
      setStartIndex1(startIndex1 - 1);
    }
  };

  const handleNext2 = () => {
    if (startIndex2 + 5 < imagess2?.length) {
      setStartIndex2(startIndex2 + 1);
    }
  };

  const handlePrev2 = () => {
    if (startIndex2 > 0) {
      setStartIndex2(startIndex2 - 1);
    }
  };

  const handleNext3 = () => {
    if (startIndex3 + 5 < images3?.length) {
      setStartIndex3(startIndex3 + 1);
    }
  };

  const handlePrev3 = () => {
    if (startIndex3 > 0) {
      setStartIndex3(startIndex3 - 1);
    }
  };
  
  const handleNext4 = () => {
    if (startIndex4 + 5 < images4?.length) {
      setStartIndex4(startIndex4 + 1);
    }
  };

  const handlePrev4 = () => {
    if (startIndex4 > 0) {
      setStartIndex4(startIndex4 - 1);
    }
  };

  const handleNext5 = () => {
    if (startIndex5 + 5 < imagess2.length) {
      setStartIndex5(startIndex5 + 1);
    }
  };

  const handlePrev5 = () => {
    if (startIndex5 > 0) {
      setStartIndex5(startIndex5 - 1);
    }
  };

  const handleNext6 = () => {
    if (startIndex6 + 5 < images6?.length) {
      setStartIndex6(startIndex6 + 1);
    }
  };

  const handlePrev6 = () => {
    if (startIndex6 > 0) {
      setStartIndex6(startIndex6 - 1);
    }
  };
  const handleNext7 = () => {
    if (startIndex7 + 5 < images7?.length) {
      setStartIndex7(startIndex7 + 1);
    }
  };

  const handlePrev7 = () => {
    if (startIndex7 > 0) {
      setStartIndex7(startIndex7 - 1);
    }
  };

  const getVisibleImages = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return imagess?.slice(startIndex, startIndex + 4);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return imagess?.slice(startIndex, startIndex + 3);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return imagess?.slice(startIndex, startIndex + 3);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return imagess?.slice(startIndex, startIndex + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return imagess?.slice(startIndex, startIndex + 3);
    } else {
      return imagess?.slice(startIndex, startIndex + 3); // شاشات صغيرة
    }
  };


  const getVisibleImages1 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return imagess1?.slice(startIndex1, startIndex1 + 5);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return imagess1?.slice(startIndex1, startIndex1 + 5);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return imagess1?.slice(startIndex1, startIndex1 + 4);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return imagess1?.slice(startIndex1, startIndex1 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return imagess1?.slice(startIndex1, startIndex1 + 3);
    } else {
      return imagess1?.slice(startIndex1, startIndex1 + 2); // شاشات صغيرة
    }
  };


  const getVisibleImages2 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return imagess2?.slice(startIndex2, startIndex2 + 5);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return imagess2?.slice(startIndex2, startIndex2 + 5);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return imagess2?.slice(startIndex2, startIndex2 + 4);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return imagess2?.slice(startIndex2, startIndex2 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return imagess2?.slice(startIndex2, startIndex2 + 3);
    } else {
      return imagess2?.slice(startIndex2, startIndex2 + 2); // شاشات صغيرة
    }
  };

  const getVisibleImages3 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return images3?.slice(startIndex3, startIndex3 + 5);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return images3?.slice(startIndex3, startIndex3 + 5);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return images3?.slice(startIndex3, startIndex3 + 4);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return images3?.slice(startIndex3, startIndex3 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return images3?.slice(startIndex3, startIndex3 + 3);
    } else {
      return images3?.slice(startIndex3, startIndex3 + 2); // شاشات صغيرة
    }
  };

  const getVisibleImages4 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return images4?.slice(startIndex4, startIndex4 + 5);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return images4?.slice(startIndex4, startIndex4 + 5);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return images4?.slice(startIndex3, startIndex4 + 4);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return images4?.slice(startIndex4, startIndex4 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return images4?.slice(startIndex4, startIndex4 + 3);
    } else {
      return images4?.slice(startIndex4, startIndex4 + 2); // شاشات صغيرة
    }
  };

  const getVisibleImages5 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return imagess2.slice(startIndex5, startIndex5 + 5);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return imagess2.slice(startIndex5, startIndex5 + 5);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return imagess2.slice(startIndex5, startIndex5 + 4);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return imagess2.slice(startIndex5, startIndex5 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return imagess2.slice(startIndex5, startIndex5 + 3);
    } else {
      return imagess2.slice(startIndex5, startIndex5 + 2); // شاشات صغيرة
    }
  };

  const getVisibleImages6 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return images6?.slice(startIndex6, startIndex6 + 4);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return images6?.slice(startIndex6, startIndex6 + 3);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return images6?.slice(startIndex6, startIndex6 + 3);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return images6?.slice(startIndex6, startIndex6 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return images6?.slice(startIndex6, startIndex6 + 3);
    } else {
      return images6?.slice(startIndex6, startIndex6 + 3); // شاشات صغيرة
    }
  };

  const getVisibleImages7 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return images7?.slice(startIndex7, startIndex7 + 4);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return images7?.slice(startIndex7, startIndex7 + 3);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return images7?.slice(startIndex7, startIndex7 + 3);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return images7?.slice(startIndex7, startIndex7 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return images7?.slice(startIndex7, startIndex7 + 3);
    } else {
      return images7?.slice(startIndex7, startIndex7 + 2); // شاشات صغيرة
    }
  };
  const getVisibleImages8 = () => {
    if (window.innerWidth >= 1400) { // شاشات XL
      return images_difalt?.slice(startIndex8, startIndex8 + 5);
    } else if (window.innerWidth >= 1200) { // شاشات MD
      return images_difalt?.slice(startIndex8, startIndex8 + 3);
    } else if (window.innerWidth >= 992) { // شاشات MD
      return images_difalt?.slice(startIndex8, startIndex8 + 3);
    } else if (window.innerWidth >= 768) { // شاشات MD
      return images_difalt?.slice(startIndex8, startIndex8 + 3);
    } else if (window.innerWidth >= 576) { // شاشات MD
      return images_difalt?.slice(startIndex8, startIndex8 + 3);
    } else {
      return images_difalt?.slice(startIndex8, startIndex8 + 3); // شاشات صغيرة
    }
  };
  
  const [visibleImage, setVisibleImage] = useState(8);
  const [showMore, setShowMore] = useState(false);

  const toggleImages = () => {
    setShowMore(!showMore);
    if (showMore) {
      setVisibleImage(8); // إخفاء الصور الإضافية
    } else {
      setVisibleImage(categorys.length); // عرض جميع الصور
    }
  };


  // const [likedIds, setLikedIds] = useState([]);
  // const toggleLike = (id) => {
  //   if (likedIds.includes(id)) {
  //     setLikedIds(prev => prev.filter(i => i !== id)); // إزالة الـ id من القائمة إذا كان موجودًا
  //   } else {
  //     setLikedIds(prev => [...prev, id]); // إضافة الـ id إلى القائمة إذا لم يكن موجودًا
  //     dispatch(addFavorite({ product_id: id }));    }
  // };

  const [likedIds, setLikedIds] = useState([]);

const toggleLike = (product) => {
  const isLiked = likedIds.includes(product.id);
  const updatedFavorite = !product.favorite; // تغيير قيمة favorite بعد النقر

  // تحديث likedIds وحالة المفضلة
  if (isLiked) {
    setLikedIds((prev) => prev.filter((id) => id !== product.id));
  } else {
    setLikedIds((prev) => [...prev, product.id]);
  }
  // إرسال حالة المفضلة الجديدة إلى الـ backend
  dispatch(addFavorite({ product_id: product.id}));
};

const cookie_id ="54c14fce-c8c8-472e-b987-7457ebfe13f9"
useEffect(() => {
  dispatch(category())
  dispatch(brands())
  dispatch(recommended())
  dispatch(offers())
  dispatch(new_arrivals())
  dispatch(best_of())
  dispatch(store())
  dispatch(influencer())
  dispatch(ads())
  dispatch(latest())
  dispatch(favorites({user_id:cookie_id}))
}, [likedIds])

const images_difalt = [
  { src: img, title: 'Photo 1', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img2, title: 'Photo 2', p: "AMERIX BIO TIN 60 PIECES hello mohamed", price: "469.000" },
  { src: img3, title: 'Photo 3', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img4, title: 'Photo 4', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img5, title: 'Photo 5', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img6, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img7, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img8, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img9, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img10, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img11, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
  { src: img11, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
];

// هتجيب البيانات المخزنة من الـ localStorage بشكل مباشر
const getLocalStorage = window.localStorage.getItem('UserInfo');

// هتعمل parse للبيانات لو موجودة
const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;

// استخراج التوكن من البيانات المخزنة

const token = valueLocalStorage && valueLocalStorage.data 
  ? (valueLocalStorage?.data?.token || 
     (valueLocalStorage?.data?.data && valueLocalStorage.data.data.user 
      ? valueLocalStorage?.data?.data?.user?.token 
      : null)) 
  : null;
  
  return (
    <div className="Home p-0 m-0">
      <Header />
      {/* {showToken &&  */}
        <Register />
      {/* } */}

      <div className="carousel container">
      {loadingAds && <Loader />}

        <i className="font-size2" onClick={prevSlide}><FontAwesomeIcon icon={faChevronLeft}/></i>
        <img src={images[currentIndex]?.image_url} alt={`slide ${currentIndex}`} onClick={() => gotoMain(images[currentIndex]?.id)}/>
        <i className="font-size2" onClick={nextSlide}><FontAwesomeIcon icon={faChevronRight}/></i>
        <div className="dots-container" >
          {images.map((e, index) => (
            <>
            <span
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            >

            </span>
            </>
          ))}
        </div>
      </div>

      <div className="containerGallery container mt-5 g-0">
      {loadingBrands && <Loader />}

        <div className="row align-items-center justify-content-between g-0">
          <div className="col-1 icon g-0">
            <img src={icon9} className="iconLeft" onClick={handlePrev}/>
          </div>
          {getVisibleImages().map((image, index) => (
            <div key={index} className={`col-${window.innerWidth >= 1400 ? 2 : window.innerWidth >= 1200 ? 2 : window.innerWidth >= 992 ? 2 : window.innerWidth >= 768 ? 3 : window.innerWidth >= 576 ? 3 : 3}`}>
              <p onClick={() => gotoBrand(image?.id)} className="bordar">
                <img  src={image?.logo} alt={`img-${index}`} />
                <p className="NameBrand">{image?.name}</p>
              </p>
            </div>
          ))}
          <div className="col-1 icon g-0">
          <img src={icon9} className="iconRitgh" onClick={handleNext}/>
          </div>
        </div>
      </div>


      <div className=" container_grid container">
      {loadingCategory && <Loader />}
        <div className="bordar">
          <div className="colorwhite ">
            <div className="row justify-content-around text-center align-i g-0">
              {categorys?.data?.data?.slice(0, visibleImage).map((e, index) => (

                <div key={index} className={`col-5 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 text-center `}>
                  <p className="text-center" onClick={() => gotoCategory(e.name)}>
                      <img key={index} src={e.image} alt={`Image ${index + 1}`} />
                  </p>
                  <p>{e.name}</p>
                </div>
              ))}
            </div>
            {categorys?.data?.data?.length > 8 && (
                <div onClick={toggleImages} className="show-more col-5 col-md-3 col-lg-2 justify-content-center">
                  {showMore ? ` ${t("HIDE")}` : `${t('SHOW MORE')}`}
                </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="img12 container">
      {loadingLatest && <Loader />}

        <img src={Latests?.data?.data?.image_url}/>
      </div>
      <div className="title_slider container">
        <p className="font-size2">Recommend for You</p>
      </div>

      <div className="gallery1 container">
      {loadingRecommended && <Loader />}

  <div className="col-1 text-center icon2">
  <img src={icon9} className="iconLeft" onClick={handlePrev1} />
  </div>
  <div className="row align-items-center justify-content-between g-0">
  {(getVisibleImages1()?.length > 0 ? getVisibleImages1() : getVisibleImages8()).map((product, index) => (
         <div className="box col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center" key={index}>
            <div className="border_box">
              <div className="padding_box">
              <i
                className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                onClick={() => {
                  toggleLike(product);
                  handelAdd_wishlist(product.id);
              }}
              style={{ color: likedIds.includes(product?.id) || product?.favorite ? 'red' : 'white' }}  // تحديث اللون
              >
                <FontAwesomeIcon icon={faHeart} />
              </i>
              {product?.discount && <p className="discount">Off {product?.discount}</p>}
              {product?.main_image &&<img onClick={() => gotoProductDetails(product)} src={product?.main_image} alt={`img-${index}`} />}
              <p className="discraptoin">{product?.description}</p>
              <div className="price align-items-center">
                <p>{product?.new_price}</p>
                {product?.old_price && <p className="old_price">{product?.old_price}</p>}
              </div>
              </div>
            </div>
          </div>
    ))}
  </div>
  <div className="col-1 text-center icon2">
    <img src={icon9} className="iconRitgh" onClick={handleNext1} />
  </div>
</div>
      
      <div className="img12 container">
      {loadingLatest && <Loader />}
        <img src={Latests?.data?.data?.image_url}/>
      </div>
      
      <div className="title_slider container">
        <p className="font-size2">Bast of</p>
      </div>
      
      <div className="gallery1 container">
      {loadingBest_of && <Loader />}
  <div className="col-1 text-center icon2">
    <img src={icon9} className="iconLeft " onClick={handlePrev2} />
  </div>
  <div className="row align-items-center justify-content-between g-0">
  {(getVisibleImages2()?.length > 0 ? getVisibleImages3() : getVisibleImages8()).map((product, index) => (
         <div className="box col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center" key={index}>
         <div className="border_box">
           <div className="padding_box">
           <i
                className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                onClick={() => {
                  toggleLike(product);
                  handelAdd_wishlist(product.id);
              }}
              style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : 'white' }}  // تحديث اللون
              >
                <FontAwesomeIcon icon={faHeart} />
              </i>
           {product?.discount && <p className="discount">Off {product?.discount}</p>}
           {product.main_image &&<img onClick={() => gotoProductDetails(product)} src={product.main_image} alt={`img-${index}`} />}
           <p className="discraptoin">{product?.description}</p>
           <div className="price align-items-center">
             <p>{product?.new_price}</p>
             {product?.old_price && <p className="old_price">{product?.old_price}</p>}
           </div>
           </div>
         </div>
       </div>
    ))}
  </div>
  <div className="col-1 text-center icon2">
    <img src={icon9} className="iconRitgh" onClick={handleNext2} />
  </div>
</div>
      
      <div className="img12 container">
      {loadingLatest && <Loader />}
        <img src={Latests?.data?.data?.image_url}/>
      </div>
      
      <div className="title_slider container">
        <p className="font-size2">New arrival</p>
      </div>
      
      <div className="gallery1 container">
      {loadingNew_arrivals && <Loader />}
  <div className="col-1 text-center icon2">
    <img src={icon9} className="iconLeft" onClick={handlePrev3} />
  </div>
  <div className="row align-items-center justify-content-between g-0">
  {(getVisibleImages3()?.length > 0 ? getVisibleImages3() : getVisibleImages8()).map((product, index) => (
         <div className="box col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center" key={index}>
            <div className="border_box">
              <div className="padding_box">
              <i
                className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                onClick={() => {
                  toggleLike(product);
                  handelAdd_wishlist(product.id);
              }}
              style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : 'white' }}  // تحديث اللون
              >
                <FontAwesomeIcon icon={faHeart} />
              </i>
              {product?.discount && <p className="discount">Off {product?.discount}</p>}
              {product.main_image &&<img onClick={() => gotoProductDetails(product)} src={product.main_image} alt={`img-${index}`} />}
              <p className="discraptoin">{product?.description}</p>
              <div className="price align-items-center">
                <p>{product?.new_price}</p>
                {product?.old_price && <p className="old_price">{product?.old_price}</p>}
              </div>
              </div>
            </div>
          </div>
    ))}
  </div>
  <div className="col-1 text-center icon2">
    <img src={icon9} className="iconRitgh" onClick={handleNext3} />
  </div>
</div>

      <div className="img12 container">
      {loadingLatest && <Loader />}
        <img src={Latests?.data?.data?.image_url}/>
      </div>
      
      <div className="title_slider container">
        <p className="font-size2">Offers</p>
      </div>
      
      <div className="gallery1 container">
      {loadingOffers && <Loader />}

      <div className="col-1 text-center icon2">
        <img src={icon9} className="iconLeft" onClick={handlePrev4} />
      </div>
      <div className="row align-items-center justify-content-between g-0">
      {(getVisibleImages4()?.length > 0 ? getVisibleImages3() : getVisibleImages8()).map((product, index) => (
         <div className="box col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center" key={index}>
          <div className="border_box">
            <div className="padding_box">
            <i
                className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                onClick={() => {
                  toggleLike(product);
                  handelAdd_wishlist(product.id);
              }}
              style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : 'white' }}  // تحديث اللون
              >
                <FontAwesomeIcon icon={faHeart} />
              </i>
            {product?.discount && <p className="discount">Off {product?.discount}</p>}
            {product.main_image &&<img onClick={() => gotoProductDetails(product)} src={product.main_image} alt={`img-${index}`} />}
            <p className="discraptoin">{product?.description}</p>
            <div className="price align-items-center">
              <p>{product?.new_price}</p>
              {product?.old_price && <p className="old_price">{product?.old_price}</p>}
            </div>
            </div>
          </div>
        </div>
        ))}
      </div>
      <div className="col-1 text-center icon2">
        <img src={icon9} className="iconRitgh" onClick={handleNext4} />
      </div>
    </div>

      <div className="gallery1 container">

        <div className="col-1 text-center icon2">
          <img src={icon9} className="iconLeft" onClick={handlePrev5}/>
        </div>
          <div className="mm row align-items-center justify-content-between g-0">
            {getVisibleImages5().map((image, index) => (
         <div className="box col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center" key={index}>
                <img className="img_box2" key={index} src={image.src} alt={`img-${index}`} />
              </div>
            ))}
          </div>
          <div className="col-1 text-center icon2">
        <img src={icon9} className="iconRitgh" onClick={handleNext5}/>
        </div>
      </div>

      <div className="title_slider container">
        <p className="font-size2">Store</p>
      </div>
      <div className="containerGallery container mt-5 g-0">
      {loadingInfluencer && <Loader />}

      <div className="row align-items-center justify-content-between g-0">
        <div className="col-1 icon g-0">
           <img src={icon9} className="iconLeft" onClick={handlePrev6}/>
        </div>
        {getVisibleImages6().map((image, index) => (
          <div key={index} className={`col-${window.innerWidth >= 1400 ? 2 : window.innerWidth >= 1200 ? 2 : window.innerWidth >= 992 ? 2 : window.innerWidth >= 768 ? 3 : window.innerWidth >= 576 ? 3 : 3}`}>
              <p onClick={() => gotoBrand(image?.id)} className="bordar">
              <img  src={image?.image} alt={`img-${index}`} />
              <p className="NameBrand">{image?.name}</p>
            </p>
          </div>
        ))}
        <div className="col-1 icon g-0">
        <img src={icon9} className="iconRitgh" onClick={handleNext6}/>
        </div>
      </div>
    <div className="hr"></div>

    </div>


    <div className="title_slider container">
        <p className="font-size2">Influencer</p>
      </div>
    <div className="containerGallery container mt-5 g-0">
    {loadingStore && <Loader />}

      <div className="row align-items-center justify-content-between g-0">
        <div className="col-1 icon g-0">
           <img src={icon9} className="iconLeft" onClick={handlePrev7}/>
        </div>
        {getVisibleImages7().map((image, index) => (
          <div key={index} className={`col-${window.innerWidth >= 1400 ? 2 : window.innerWidth >= 1200 ? 2 : window.innerWidth >= 992 ? 2 : window.innerWidth >= 768 ? 3 : window.innerWidth >= 576 ? 3 : 3}`}>
              <p onClick={() => gotoBrand(image?.id)} className="bordar">
              <img  src={image?.image} alt={`img-${index}`} />
              <p className="NameBrand">{image.name}</p>
            </p>
          </div>
        ))}
        <div className="col-1 icon g-0">
        <img src={icon9} className="iconRitgh" onClick={handleNext7}/>
        </div>
      </div>
    </div>
    <div className="hr"></div>

    <div className="foot container">
      <div className="row">
        <h2 className="h2 col-12 col-md-6">
          {t('YOUR ONE-STEP SHOP TO')}
          <br />
          {t('ALL WHAT YOU NEED')}
        </h2>
        <div className="col-3 m-auto mt-4 mt-md-0">
          <div>
            <h2>2000+</h2>
            <p>{t('EMPLOYERS')}</p>
          </div>
          <div>
            <h2>9000+</h2>
            <p>{t('PRODUCTS')}</p>
          </div>
        </div>
        <div className="col-3 m-auto mt-4 mt-md-0">
          <div>
            <h2>15+</h2>
            <p>{t('LANGUAGES')}</p>
          </div>
          <div>
            <h2>3</h2>
            <p>{t('WAREHOUSES')}</p>
          </div>
        </div>
      </div>
    </div>
      <Footer />
    </div>
  );
}

export default Home;