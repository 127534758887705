import { createSlice } from '@reduxjs/toolkit';
// import { offers } from './API';

export const HomeSlice = createSlice({
  name: 'products',
  initialState: {
    favoritse: [],
    product: null,
    
  },
  reducers: {
    addFavorites: (state, action) => {
      state.favoritse = action.payload.favoritse;
    },
    productDetails: (state, action) => {
      state.product = action.payload;

    },
    
  },
  
});

export const {addFavorites, productDetails} = HomeSlice.actions;
export default HomeSlice.reducer;
