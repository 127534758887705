import './brand.css';
import img9 from "../images/IMG-20240723-WA0008.jpg"
import img8 from '../images/IMG-20240723-WA0008.jpg'
import img from "../images/IMG-20240723-WA0006.jpg"
import img2 from "../images/IMG-20240723-WA0008.jpg"
import img3 from "../images/IMG-20240723-WA0013.jpg"
import img4 from "../images/IMG-20240723-WA0018.jpg"
import img5 from "../images/IMG-20240723-WA0021.jpg"
import img6 from "../images/IMG-20240723-WA0022.jpg"
import img7 from "../images/IMG-20240723-WA0023.jpg"
import img10 from "../images/IMG-20240723-WA0008.jpg"
import img11 from "../images/IMG-20240723-WA0010.jpg"

import {faCheck, faChevronLeft, faChevronRight, faHeart, faShare, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { searsh_brand, show_brand, product_reviews, addFavorite, sort_products, influencers_follow, filter_data, sort_data, filter_brducts, category_data } from "../Redux/API";
import { productDetails } from "../Redux/HomeSlice";
import Loader from "../Loader";
import {  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  RedditIcon,
} from 'react-share';


import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'; // استيراد التنسيقات الافتراضية

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link, NavLink } from 'react-router-dom';
function Brand() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  


  const gotoCategory = (aut) => {
    navigate('/SubSupCategory');
    dispatch(category_data({aut}));
  };


  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshBroduct(e.target.value); 
    };
  // جلب بيانات البحث أو العلامة التجارية عند تغيير قيمة البحث
  useEffect(() => {
    if (searshBroduct) {
      dispatch(searsh_brand( searshBroduct )); // البحث عن المنتجات
    } else {
      dispatch(show_brand()); // عرض العلامات التجارية الأصلية
    }
  }, [searshBroduct, dispatch]);

  

  const gotoProductDetails = (product) => {
    if (!product || !product.id) {
      console.error('Product data is missing or invalid');
      return;
    }
    // إرسال البيانات إلى Redux
    dispatch(productDetails(product));
    dispatch(product_reviews(product.id));

    // التنقل إلى صفحة التفاصيل
    navigate('/Product');
  };
  const loadingSearsh_brand = useSelector(state => state.Data.loadingSearsh_brand);
  const searsh_brands = useSelector(state => state.Data.searsh_brand);

  const show_brands = useSelector(state => state.Data.show_brand);
  const loadingShow_brand = useSelector(state => state.Data.loadingShow_brand);

  
  const [icon, setIcon] = useState(false);
  const [text, setText] = useState();
  function handelClick() {
      setIcon(!icon);
  }

  const handleFollow = (id) => {
    dispatch(influencers_follow({ id })); // بعت الـ id للـ backend
  };

  
  useEffect(() => {
    dispatch(sort_products(text)); 
    dispatch(filter_data()); 
    dispatch(sort_data()); 
  }, [dispatch, text]);


const sort_product = useSelector(state => state.Data.sort_products);
const loadingSort_productS = useSelector(state => state.Data.loadingSort_productS);
const influencers_follows = useSelector(state => state.Data.influencers_follow);

const filters_data = useSelector(state => state.Data.filter_data);
const sorts_data = useSelector(state => state.Data.sort_data);
const filter_brduct = useSelector(state => state.Data.filter_brducts);
const loadingFilter_brduct = useSelector(state => state.Data.loadingFilter_brducts);


console.log(show_brands)
console.log(filter_brduct)
console.log(sort_product)
console.log(searsh_brands)
console.log('777777777777777777777777777777777777777777777777777777777777777777777')




  const [likedIds, setLikedIds] = useState([]);

  const toggleLike = (product) => {
    const isLiked = likedIds.includes(product.id);
    const updatedFavorite = !product.favorite; // تغيير قيمة favorite بعد النقر
  
    // تحديث likedIds وحالة المفضلة
    if (isLiked) {
      setLikedIds((prev) => prev.filter((id) => id !== product.id));
    } else {
      setLikedIds((prev) => [...prev, product.id]);
    }
    // إرسال حالة المفضلة الجديدة إلى الـ backend
    dispatch(addFavorite({ product_id: product.id}));
  };











    const product = ["sofa", 'interior', 'Chairs', 'Tables', 'Chairs', 'Chairs', 'Chairs']

    const [openIndex, setOpenIndex] = useState(null);
      const [selectedFilters, setSelectedFilters] = useState({
        brands: [],
        categories: [],
        prices: [],
        discounts: [],
        colors: [],
        sizes: [],
      });
      console.log(selectedFilters)
      console.log('ooooooooooooooooooooooooooooooooooooooooooooooooooooo')

      const handleFilterDataClick = () => {
        dispatch(filter_brducts({selectedFilters})); 
      };
        const brands = {
          title: t('brands'),
          subItems: filters_data?.data?.data?.brands,
        };
      
        const items = [
          {
            title: t('categories'),
            key: 'categories', 
            subItems: filters_data?.data?.data?.categories,
          },
          {
            title: t('prices'),
            key: 'prices', 
            subItems: filters_data?.data?.data?.prices,
          },
          {
            title: t('discounts'),
            key: 'discounts', 
            subItems: filters_data?.data?.data?.discounts,
          },
          {
            title: t('colors'),
            key: 'colors', 
            subItems: filters_data?.data?.data?.colors,
          },
          {
            title: t('sizes'),
            key: 'sizes', 
            subItems: filters_data?.data?.data?.sizes,
          },
        ];

        const handleAddFilter = (type, filter) => {
          setSelectedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
        
            // تأكد من أن `updatedFilters[type]` هو مصفوفة قبل محاولة إضافة الفلتر
            if (!Array.isArray(updatedFilters[type])) {
              updatedFilters[type] = [];
            }
        
            // لو الفلتر هو "brands"، مرر `id` فقط
            if (type === 'brands') {
              if (updatedFilters[type].includes(filter.id)) {
                // لو الفلتر موجود، امسحه
                updatedFilters[type] = updatedFilters[type].filter(id => id !== filter.id);
              } else {
                // لو مش موجود، ضيفه
                updatedFilters[type] = [...updatedFilters[type], filter.id];
              }
            } 
            // للفئات الأخرى، مرر `title` فقط
            else {
              if (updatedFilters[type].includes(filter.title)) {
                // لو الفلتر موجود، امسحه
                updatedFilters[type] = updatedFilters[type].filter(title => title !== filter.title);
              } else {
                // لو مش موجود، ضيفه
                updatedFilters[type] = [...updatedFilters[type], filter.title];
              }
            }
        
            return updatedFilters;
          });
        };

    const icons3 = [['','','',''], ['','',''], ['','']]
    const [openInde, setOpenInde] = useState(2);
    const [selectedIndex, setSelectedIndex] = useState(2);

    const handleRs = (index) => {
      setOpenInde(index)
    };


    const [circles, setCircles] = useState([1, 2, 3]);
    const [backendNumber, setBackendNumber] = useState(50); // أو الرقم اللي هتاخده من الباك اند
    const [activeIndex, setActiveIndex] = useState(0); // لحفظ الدائرة النشطة
  
    const handleRightArrow = () => {
      if (activeIndex < circles.length - 1) {
        setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
      } else {
        setCircles((prevCircles) => {
          if (prevCircles.every(num => num < backendNumber)) {
            return prevCircles.map(num => num + 1);
          }
          return prevCircles;
        });
      }
    };

    // فانكشن لتحديث الأرقام عند الضغط على السهم الشمال
    const handleLeftArrow = () => {
      if (activeIndex > 0) {
        setActiveIndex((prevActiveIndex) => prevActiveIndex - 1);
      } else {
        setCircles((prevCircles) => {
          if (prevCircles.every(num => num > 1)) {
            return prevCircles.map(num => num - 1);
          }
          return prevCircles;
        });
      }
    };

  const [likedIndexes, setLikedIndexes] = useState([]);

  const [showShareButtons, setShowShareButtons] = useState(false);
  const toggleShareButtons = () => {
      setShowShareButtons(prev => !prev);
  };

  const copyToClipboard = () => {
      navigator.clipboard.writeText(product?.url || '').then(() => {
          alert('Product link copied to clipboard!');
      }).catch(err => {
          console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="Brand">
        <Header />
        {/* <div className='headerB'>
            <p>
                <span>Home</span>
                <i ><FontAwesomeIcon icon={faChevronRight}/></i>
            </p>
            <p>
                <span>Men</span>
                <i ><FontAwesomeIcon icon={faChevronRight}/></i>
            </p>
            <p>
                <span>T-shirts</span>
            </p>
        </div> */}
        <div className='containerBrand container'>
          <div className='row justify-content-between align-items-end'>
          {loadingShow_brand && <Loader />}
            <div className='col-12 col-sm-5 col-md-5 col-lg-3'>
                <div className='border_box'>
                  <img src={show_brands?.data?.data?.brand?.logo}/>
                </div>
                <div className='image mt-3'>
                    <div className='border_box_P1'>
                    <span onClick={() => handleFollow(show_brands?.data?.data?.brand?.id)}>
                      {t('+Follow')}
                    </span>                    </div>
                    <div className='box_Share' onClick={(copyToClipboard, toggleShareButtons)}>
                        <span className='Share'>
                            <FontAwesomeIcon icon={faShare} />
                        </span>
                    </div>
                </div>
                {showShareButtons && (
                        <div className="shareButtons mt-3 pt-2">
                          <FacebookShareButton className='' url={product?.url || ''}>
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                          <TwitterShareButton className='' url={product?.url || ''}>
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <WhatsappShareButton className='' url={product?.url || ''}>
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <LinkedinShareButton className='' url={product?.url || ''}>
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                          <PinterestShareButton className='' url={product?.url || ''}>
                            <PinterestIcon size={32} round />
                          </PinterestShareButton>
                          <TelegramShareButton className='' url={product?.url || ''}>
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                        </div>
                      )}
            </div>
            <div className='col-12 col-sm-5 col-md-5 col-lg-3 justify-content-around'>
                <h2>{show_brands?.data?.data?.brand?.name}</h2>
                <div className=''>
                  <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} 
                  placeholder={t('products search...')}
                />               
                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-4 mt-sm-2 p-1'>
              <ul style={{ margin: 0, padding: '20px 30px', backgroundColor: 'white', borderRadius: '20px', width: '100%', height: '100%', textAlign: 'start' }}>
              <PerfectScrollbar style={{ height: '100%' }} options={{ suppressScrollX: true }}>
              <p className='font-size2 pt-2'>{t('Store categories')}</p>
              {show_brands?.data?.data?.brand.categories.map((e, index) => (
                  <li key={index} style={{ marginTop: '10px', color: 'black', width: '100%', fontWeight: '400', padding: '1%', cursor: 'pointer' }} onClick={() => gotoCategory(e.name)}>
                    {e.name}
                  </li>
                ))}
            </PerfectScrollbar>
              </ul>
            </div>
          </div>
        </div>
        <span className='hr'></span>













        <div className='containe container'>
          <div className='border_box2'>
            <div className='background'>
            <p className='Produ'>{filters_data?.data?.data?.total_products} {t('Products')}</p>
            <div>
                {icons3.map((e, i) => (
                  <div key={i}    className={
                    e.length === 2 
                      ? 'show-on-small1' 
                      : e.length === 3 
                      ? 'show-on-small2' 
                      : e.length === 4 
                      ? 'show-on-small3' 
                      : ''
                  }>
                    <p
                      className={`p ${openInde === i ? 'selected' : ''}`}
                      onClick={() => handleRs(i)}
                    >
                      {e.map((item, index) => (
                        <span key={index} className={openInde === i ? 'selected' : ''}>
                          {item}
                        </span>
                      ))}
                    </p>
                    {openInde === i && (
                      <span
                        className="arrow"
                        style={{
                          marginInlineStart:
                            document.documentElement.dir === 'rtl'
                              ? '-7px'
                              : openInde === 0 || openInde === 1 || openInde === 2
                              ? '-25px'
                              : '',
                        }}
                      >
                        &#9650;
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <div className="boxList">
                <div className="level">
                <p>{t('Sort by')}: <span>{text}</span></p>
                <i onClick={handelClick} className={`${icon ? 'activ' : 'noActiv'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                </div>

                {icon &&
                  <div className="levels">
                    {sorts_data?.data?.data?.filter_titles.map((e, i) => 
                      <p key={i} onClick={() => (setText(e), setIcon(!icon))}>{e}</p>
                    )}
                  </div>
                }
              </div>
          </div>
          </div>
        </div>



        <div className="main-container container">
            <div className="main-cont col-4" style={{ display: openInde === 0 || openInde === 1 ? 'none' : 'block' }}>
            <div className="Filter_icon">
          <p>Filter</p>
          <i className="i" onClick={handleFilterDataClick}>
            <FontAwesomeIcon icon={faCheck} />
          </i>
        </div>

        {/* عرض البراندات */}
        <div className="main-item">
          <div className="item-headerr" onClick={() => setOpenIndex(openIndex === 0 ? null : 0)}>
            <p>{brands.title}</p>
            <p className="arrow">
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </p>
          </div>
          {openIndex === 0 && (
            <div className="sub-items">
               {brands?.subItems?.map((brand, brandIndex) => (
                <div
                  key={brandIndex}
                  className="sub-item d-flex justify-content-between hover-bg"
                  onClick={() => handleAddFilter('brands', { id: brand.id })}
                >
                  <p>{brand.filter_title}</p>
                    <div className='m d-flex '>
                      <p className='mx-2'>{brand.quantity}</p>
                      {selectedFilters.brands?.includes(brand.id) && <span>✅</span>}
                    </div>
                </div>
              ))}

            </div>
          )}
        </div>

        {items?.map((item, index) => (
          <div key={index} className="main-item">
            <div className="item-headerr" onClick={() => setOpenIndex(openIndex === index + 1 ? null : index + 1)}>
              <p>{item.title}</p>
              <p className="arrow">
                <i>
                  <FontAwesomeIcon icon={faChevronRight} />
                </i>
              </p>
            </div>
            {openIndex === index + 1 && (
              <div className="sub-items">
                  {item?.subItems?.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      className="sub-item d-flex justify-content-between hover-bg"
                      onClick={() => handleAddFilter(item.key, { title: subItem.filter_title })}
                    >
                      <p>{subItem.filter_title}</p>
                      <div className='m d-flex '>
                      <p className='mx-2'>{subItem.quantity}</p>
                      {selectedFilters[item.key]?.includes(subItem.filter_title) && <span>✅</span>}
                      </div>
                    </div>
                  ))}

              </div>
            )}
          </div>
        ))}
      </div>
    {/* </div> */}


            <div className="gallery1 container">
            <div className="row align-items-center justify-content-around g-0">
              {loadingSearsh_brand && <Loader />}
              {loadingSort_productS && <Loader />}
              {loadingFilter_brduct && <Loader />}
              {loadingShow_brand && <Loader />}
                {/* عرض المنتجات بناءً على وجود الفلتر أو الترتيب أو البحث */}
                {filter_brduct ? (
                  // عرض نتائج الفلتر
                  filter_brduct?.data?.data?.products.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>        <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : sort_product ? (
                  // عرض نتائج الترتيب
                  sort_product?.data?.data?.products.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>
                              <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : searsh_brands ? (
                  // عرض نتائج البحث
                  searsh_brands?.data?.data.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>        <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  // عرض جميع المنتجات الخاصة بالعلامة التجارية
                  show_brands?.data?.data?.brand?.products.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>        <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
       
      </div>
     
        </div>
        {/* <div className="circles container">
            <p className='pr'><i onClick={handleLeftArrow}><FontAwesomeIcon icon={faChevronLeft} /></i>  previous Page</p>
                {circles.map((num, index) => (
                <div key={index} className={`circle ${index === activeIndex ? 'active' : ''}`}>
                    {num}
                </div>
                ))}
                <span>...</span>
                <div className='circle'>{backendNumber}</div>
            <p className='pr'>Next Page  <i onClick={handleRightArrow}><FontAwesomeIcon icon={faChevronRight} /></i> </p>
            </div> */}
        <Footer />
    </div>
  );
}

export default Brand;