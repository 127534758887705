import './Purchasing.css';
import img8 from '../images/IMG-20240723-WA0008.jpg';
import img from "../images/IMG-20240723-WA0006.jpg";
import img2 from "../images/IMG-20240723-WA0008.jpg";
import img3 from "../images/IMG-20240723-WA0013.jpg";
import img4 from "../images/IMG-20240723-WA0018.jpg";
import img5 from "../images/IMG-20240723-WA0021.jpg";
import img6 from "../images/IMG-20240723-WA0022.jpg"
import img7 from "../images/IMG-20240723-WA0023.jpg"
import img9 from "../images/IMG-20240723-WA0007.jpg"
import img10 from "../images/IMG-20240723-WA0008.jpg"
import img11 from "../images/IMG-20240723-WA0010.jpg"
import icon9 from "../Icons/Icon-09.png"

import { faCartPlus, faChevronLeft, faChevronRight, faExchangeAlt, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState } from 'react';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
const product = [
    {id: '1',img:img, nike: "Nikce Men's  Sportswear Just Do It T-shirt", color: 'Black', size:"Large", price: '20.500'},
    {id: '1', img:img2, nike: "Nikce Men's  Sportswear Just Do It T-shirt", color: 'Black', size:"Large"}
]

function Purchasing() {
    const [counter, setCounter] = useState(1);
    const increment = () => {
      setCounter(prevCounter => prevCounter + 1);
    };
  
    const decrement = () => {
      setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 1));
    };


    const imagess = [
        { src: img, title: 'Photo 1', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img2, title: 'Photo 2', p: "AMERIX BIO TIN 60 PIECES hello mohamed", price: "469.000" },
        { src: img3, title: 'Photo 3', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img4, title: 'Photo 4', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img5, title: 'Photo 5', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img6, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img7, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img8, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img9, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img10, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img11, title: 'Photo 6', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
      ];
      const [visibleImages, setVisibleImages] = useState(imagess.slice(0, 5));
      const [startIndex, setStartIndex] = useState(0);
    
      const handleNext = () => {
        if (startIndex + 5 < imagess.length) {
          setStartIndex(startIndex + 1);
          setVisibleImages(imagess.slice(startIndex + 1, startIndex + 6));
        }
      };
    
      const handlePrev = () => {
        if (startIndex > 0) {
          setStartIndex(startIndex - 1);
          setVisibleImages(imagess.slice(startIndex - 1, startIndex + 4));
        }
      };
      const getVisibleImages = () => {
        if (window.innerWidth >= 1400) { // شاشات XL
          return imagess.slice(startIndex, startIndex + 5);
        } else if (window.innerWidth >= 1200) { // شاشات MD
          return imagess.slice(startIndex, startIndex + 4);
        } else if (window.innerWidth >= 992) { // شاشات MD
          return imagess.slice(startIndex, startIndex + 4);
        } else if (window.innerWidth >= 768) { // شاشات MD
          return imagess.slice(startIndex, startIndex + 3);
        } else if (window.innerWidth >= 576) { // شاشات MD
          return imagess.slice(startIndex, startIndex + 3);
        } else {
          return imagess.slice(startIndex, startIndex + 2); // شاشات صغيرة
        }
      };
  return (
    <div className="Purchasing">
        <Header />
        <p className='PurchasingP container'>Products</p>
        <div className='container_Purchasing container'>
            {product.map((e) => 
            <div className='container_prodects row justify-content-between'>
              <div className='col-12 col-md-6 col-lg-4 text-center'>
              <img src={e.img}/>
              </div>
                <div className='details col-12 col-md-6 col-lg-4'>
                    <p>{e.nike}</p>
                    <p>Color: {e.color}</p>
                    <p>Size: {e.size}</p>
                </div>
                <div className='box_counter col-12 col-md-6 col-lg-4 mt-sm-3 text-center'>
                    <div className="counter">
                        <span className='span1' onClick={decrement}>-</span>
                        <span className='span3'>{counter}</span>
                        <span className='span2' onClick={increment}>+</span>
                    </div>
                    <i ><FontAwesomeIcon icon={faTrash}/></i>
                    <p><span>KWD</span>{e.price}</p>
                </div>
            </div>
            )}
        </div>



        <div className='Vouchers container'>
          <div className='row justify-content-end'>
            <div className='Quantity col col-md-6'>
                <div>
                    <p>Quantity</p>
                    <p className='p2'>2</p>
                    <p>TotalPrice</p>
                    <p className='p3'><span>KWD</span> 26.400</p>
                </div>
                <div className='Promos'>
                  <span>Promos & Vouchers</span>
                  <span>ASHTIRI 1</span>
                </div>
                <div className='Promos2'>
                  <span>Discount Voucher</span>
                  <span className='Discount'>-<span className='KWD'>KWD</span> 13.200</span>
                </div>
                <div className='Promos2'>
                  <span>Shipping Fee</span>
                  <span><span className='KWD'>KWD</span> 1.500</span>
                </div>
                <div className='Promos'>
                  <span>Total Price</span>
                  <span><span className='KWD'>KWD</span> 14.700</span>
                </div>
                <div>Checkout Now</div>
                <div>Ways you can pay:</div>
                <div>
                    <img src={img3}/>
                    <img src={img3}/>
                    <img src={img3}/>
                    <img src={img3}/>
                    <img src={img3}/>
                </div>
            </div>
            </div>
        </div>
        
        <div className=" container">
        <p>For New Customers</p>
        </div>
      
      <div className="gallery1 container">
        <div className="col-1 text-center icon2">
          <img src={icon9} className="iconLeft" onClick={handlePrev} />
        </div>
        <div className="row align-items-center justify-content-between g-0">
          {getVisibleImages().map((image, index) => (
            <div className="box col-5 col-sm-3 col-md-3 col-lg-3 col-xl-2  px-lg-3 px-xl-0 text-center" key={index}>
              <div className="border_box">
                <div className="padding_box">
                  <i><FontAwesomeIcon icon={faHeart} /></i>
                  <img src={image.src} alt={`img-${index}`} />
                  <p className="discraptoin">{image.p}</p>
                  <div className="price align-items-center">
                    <p>{image.price}</p>
                    <p>{image.price}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-1 text-center icon2">
          <img src={icon9} className="iconRitgh" onClick={handleNext} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Purchasing;