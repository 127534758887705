import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Welcome": "Welcome to the website",
      "About Us": "About Us",
      "Careers": "Careers",
      "Contact US": "Contact US",
      "Stores": "Stores",
      "follow Us On": "follow Us On",
      "Category": "Category",
      "Offers": "Offers",
      "New arrival": "New arrival",
      "SHOW MORE": "SHOW MORE",
      "HIDE": "HIDE",
      "YOUR ONE-STEP SHOP TO": "YOUR ONE-STEP SHOP TO",
      "ALL WHAT YOU NEED": "ALL WHAT YOU NEED",
      "EMPLOYERS": "EMPLOYERS",
      "PRODUCTS": "PRODUCTS",
      "LANGUAGES": "LANGUAGES",
      "WAREHOUSES": "WAREHOUSES",
      "brands": "Brands",
      "categories": "Categories",
      "prices": "Prices",
      "discounts": "Discounts",
      "colors": "Colors",
      "sizes": "Sizes",
      "products": "products",
      "Sort by": "Sort by",
      "Store categories": "Store categories",
      "+Follow": "+Follow",
      "products search...": "products search...",

      "Track Orders": "Track Orders",
      "Order Number": "Order Number",
      "Order": "Order",
      "Date": "Date",
      "Status": "Status",
      "Total": "Total",
      "For": "For",
      "Items": "Items",
      "Actions": "Actions",
      "View": "View",
      "Total Quantity": "Total Quantity",
      "Total Price": "Total Price",
      "Promos & Vouchers": "Promos & Vouchers",
      "Discount Voucher": "Discount Voucher",
      "Shipping Fee": "Shipping Fee",
      "Order Received": "Order Received",
      "In Progress": "In Progress",
      "On The Way": "On The Way",
      "Delivered": "Delivered",
      "Your request has been received successfully.": "Your request has been received successfully.",
      "Your request is in progress.": "Your request is in progress.",
      "The order has been shipped. It should arrive within 24 hours.": "The order has been shipped. It should arrive within 24 hours.",
      "Your order has been successfully delivered.": "Your order has been successfully delivered.",

      "Cancel Returns & Exchanges": "Cancel Returns & Exchanges",
      "On the way to get the order": "On the way to get the order",
      "Received & inspected": "Received & inspected",
      "Your return request has been received.": "Your return request has been received.",
      "The representative is on his way to receive the product.": "The representative is on his way to receive the product.",
      "Returns": "Returns",
      "Exchanges": "Exchanges",

      "Returns & Exchanges": "Returns & Exchanges",
      "Request Returns & Exchanges": "Request Returns & Exchanges",
      "You Have No Returns History": "You Have No Returns History",
      "You Have No Exchanges History": "You Have No Exchanges History",
      
      "Personal Information": "Personal Information",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Date Of Birth": "Date Of Birth",
      "Gender": "Gender",
      "Female": "Female",
      "Male": "Male",
      "Choose Language": "Choose Language",
      "Ar": "Arabic",
      "En": "English",
      "Number": "Number",
      "Nationality": "Nationality",
      "Address": "Address",
      "Image": "Image",
      "Click Here to upload Photo": "Click Here to upload Photo",
      "Submit": "Submit",
      "Security": "Security",
      "Email": "Email",
      "Password": "Password",
      "Change Password": "Change Password",
      "Log Out": "Log Out",
      "Delete Account": "Delete Account",
      "Payment Methods": "Payment Methods",
      "Add New Card": "Add New Card",
      "Your Payment Method is empty": "Your Payment Method is empty",

      "My Points": "My Points",
      "YOUR POINTS": "YOUR POINTS",
      "Earned this month": "Earned this month",
      "Ends this month": "Ends this month",
      "points": "points",
      "Available balance": "Available balance",
      "Buy more and earn points": "Buy more and earn points",
      "One point is equal to one dinar": "One point is equal to one dinar",
      "Points expire after one year": "Points expire after one year",
      "My Orders": "My Orders",

      "Wishlist": "Wishlist",
      "Off": "Off",
      "Your Wishlist is empty": "Your Wishlist is empty",
      'Points': 'Points',

      "myAccount": "My Account",
      "myOrders": "My Orders",
      "trackOrders": "Track Orders",
      "returnsExchanges": "Returns & Exchanges",
      "wishlistInfluencer": "Wishlist & Influencer",
      "myPoints": "My Points",


      "Welcome to the new Experience": "Welcome to the new Experience",
      "Create your password": "Create your password",
      "Confirm Password": "Confirm Password",
      "CONTINUE": "CONTINUE",
      "Password changed successfully": "Password changed successfully",
      "Please log in now.": "Please log in now.",
      "VERIFY YOUR EMAIL ADDRESS": "VERIFY YOUR EMAIL ADDRESS",
      "Verify your email using the OTP sent to your email address": "Verify your email using the OTP sent to your email address",
      "Resend OTP": "Resend OTP",
      "OTP": "OTP",
      "Please enter email": "Please enter email",

      "Create your account": "Create your account",
      "Log in": "Log in",
      "Sign up": "Sign up",
      "Forgot Password": "Forgot Password",
      "OR LOGIN WITH SOCIAL": "OR LOGIN WITH SOCIAL",
      "Login Instagram": "Login Instagram",
      "Login Apple": "Login Apple",
      "welcome": "Welcome to the new Experience",
      "createAccount": "Create your account",
      "login": "Log in",
      "signup": "Sign up",
      "enterEmail": "Please enter email",
      "password": "Password",
      "firstName": "First Name",
      "lastName": "Last Name",
      "eightCharsMin": "8 Characters Minimum",
      "oneLetter": "At least one letter",
      "oneNumber": "At least one number",
      "orLoginWithSocial": "OR LOGIN WITH SOCIAL",
      "loginInstagram": "Login Instagram",
      "loginApple": "Login Apple",
      "continue": "CONTINUE",
      "loginWithGoogle": "Login in with Google",
      "loginWithFacebook": "Login in with Facebook",
      "fillFieldsWithValidInfo": "Please fill in all fields with valid information.",
      "passwordRequirements": "Password must contain uppercase, lowercase letters, and numbers.",
      "registrationFailed": "Registration failed. Please try again.",
      "generalError": "An error occurred. Please try again later.",
      "loginSuccessful": "Login successful!",
      "favorites": "Favorites",
      "new_arrival": "New Arrival"


      // أضف المزيد من الترجمات هنا
    }
  },
  ar: {
    translation: {
      "Welcome": "مرحبًا بكم في الموقع",
      "About Us": "معلومات عنا",
      "Careers": "وظائف",
      "Contact US": "اتصل بنا",
      "Stores": "المتاجر",
      "follow Us On": "اتبعنا",
      "Category": "فئة",
      "Offers": "عروض",
      "New arrival": "جديد",
      "SHOW MORE": "عرض المزيد",
      "HIDE":"إخفاء",
      "YOUR ONE-STEP SHOP TO": "متجرك الشامل لجميع احتياجاتك",
      "ALL WHAT YOU NEED": "كل ما تحتاجه",
      "EMPLOYERS": "أصحاب العمل",
      "PRODUCTS": "المنتجات",
      "LANGUAGES": "اللغات",
      "WAREHOUSES": "المستودعات",
      "brands": "العلامات التجارية",
      "categories": "الفئات",
      "prices": "الأسعار",
      "discounts": "الخصومات",
      "colors": "الألوان",
      "sizes": "المقاسات",
      "Products": "المنتجات",
      "Sort by": "ترتيب حسب",
      "Store categories": "فئات المتجر",
      "+Follow": "+ متابعة",
      "products search...": "البحث عن المنتجات...",
      
      "Track Orders": "تتبع الطلبات",
      "Order Number": "رقم الطلب",
      "Order": "الطلب",
      "Date": "التاريخ",
      "Status": "الحالة",
      "Total": "المجموع",
      "For": "لـ",
      "Items": "عنصر",
      "Actions": "الإجراءات",
      "View": "عرض",
      "Total Quantity": "الكمية الإجمالية",
      "Total Price": "إجمالي السعر",
      "Promos & Vouchers": "العروض والقسائم",
      "Discount Voucher": "قسيمة الخصم",
      "Shipping Fee": "رسوم الشحن",
      "Order Received": "تم استلام الطلب",
      "In Progress": "قيد التنفيذ",
      "On The Way": "في الطريق",
      "Delivered": "تم التوصيل",
      "Your request has been received successfully.": "تم استلام طلبك بنجاح.",
      "Your request is in progress.": "طلبك قيد المعالجة.",
      "The order has been shipped. It should arrive within 24 hours.": "تم شحن الطلب، سيصل خلال 24 ساعة.",
      "Your order has been successfully delivered.": "تم توصيل طلبك بنجاح.",

      "Cancel Returns & Exchanges": "إلغاء الإرجاع والتبديل",
      "On the way to get the order": "في الطريق لاستلام الطلب",
      "Received & inspected": "تم الاستلام والتفتيش",
      "Your return request has been received.": "تم استلام طلب الإرجاع الخاص بك.",
      "The representative is on his way to receive the product.": "المندوب في الطريق لاستلام المنتج.",
      "Returns": "إرجاع",
      "Exchanges": "تبديل",
      "Returns & Exchanges": "الإرجاع والتبديل",
      "Request Returns & Exchanges": "طلب إرجاع وتبديل",
      "You Have No Returns History": "ليس لديك سجلات إرجاع",
      "You Have No Exchanges History": "ليس لديك سجلات تبديل",

      "Personal Information": "المعلومات الشخصية",
      "First Name": "الاسم الأول",
      "Last Name": "اسم العائلة",
      "Date Of Birth": "تاريخ الميلاد",
      "Gender": "الجنس",
      "Female": "أنثى",
      "Male": "ذكر",
      "Choose Language": "اختر اللغة",
      "Ar": "العربية",
      "En": "الإنجليزية",
      "Number": "رقم الهاتف",
      "Nationality": "الجنسية",
      "Address": "العنوان",
      "Image": "الصورة",
      "Click Here to upload Photo": "اضغط هنا لرفع الصورة",
      "Submit": "إرسال",
      "Security": "الأمان",
      "Email": "البريد الإلكتروني",
      "Password": "كلمة المرور",
      "Change Password": "تغيير كلمة المرور",
      "Log Out": "تسجيل الخروج",
      "Delete Account": "حذف الحساب",
      "Payment Methods": "طرق الدفع",
      "Add New Card": "إضافة بطاقة جديدة",
      "Your Payment Method is empty": "طرق الدفع الخاصة بك فارغة",

      "My Points": "نقاطي",
      "YOUR POINTS": "نقاطك",
      "Earned this month": "تم الحصول عليها هذا الشهر",
      "Ends this month": "تنتهي هذا الشهر",
      "points": "نقاط",
      "Available balance": "الرصيد المتاح",
      "Buy more and earn points": "اشترِ المزيد واكسب نقاط",
      "One point is equal to one dinar": "النقطة الواحدة تساوي دينارًا واحدًا",
      "Points expire after one year": "النقاط تنتهي صلاحيتها بعد سنة واحدة",
      "My Orders": "طلباتي",

      "Wishlist": "قائمة الرغبات",
      "Off": "خصم",
      "Your Wishlist is empty": "قائمة الرغبات فارغة",
      'Points': 'نقاط',
      "myAccount": "حسابي",
      "myOrders": "طلباتي",
      "trackOrders": "تتبع الطلبات",
      "returnsExchanges": "إرجاع وتبادل",
      "wishlistInfluencer": "قائمة الأمنيات والمؤثرين",
      "myPoints": "نقاطي",
      "Welcome to the new Experience": "مرحبًا بكم في التجربة الجديدة",
      "Create your password": "أنشئ كلمة المرور الخاصة بك",
      "Confirm Password": "تأكيد كلمة المرور",
      "CONTINUE": "استمرار",
      "Password changed successfully": "تم تغيير كلمة المرور بنجاح",
      "Please log in now.": "يرجى تسجيل الدخول الآن.",
      "VERIFY YOUR EMAIL ADDRESS": "تأكيد عنوان بريدك الإلكتروني",
      "Verify your email using the OTP sent to your email address": "قم بتأكيد بريدك الإلكتروني باستخدام رمز التحقق المرسل إلى عنوان بريدك الإلكتروني",
      "Resend OTP": "إعادة إرسال رمز التحقق",
      "OTP": "رمز التحقق",
      "Please enter email": "يرجى إدخال البريد الإلكتروني",
      "Create your account": "أنشئ حسابك",
      "Log in": "تسجيل الدخول",
      "Sign up": "إنشاء حساب",
      "Forgot Password": "نسيت كلمة المرور",
      "OR LOGIN WITH SOCIAL": "أو تسجيل الدخول عبر وسائل التواصل",
      "Login Instagram": "تسجيل الدخول عبر إنستجرام",
      "Login Apple": "تسجيل الدخول عبر Apple",
      "welcome": "مرحبًا بك في التجربة الجديدة",
      "createAccount": "أنشئ حسابك",
      "login": "تسجيل الدخول",
      "signup": "إنشاء حساب",
      "enterEmail": "يرجى إدخال البريد الإلكتروني",
      "password": "كلمة المرور",
      "firstName": "الاسم الأول",
      "lastName": "الاسم الأخير",
      "eightCharsMin": "8 أحرف على الأقل",
      "oneLetter": "على الأقل حرف واحد",
      "oneNumber": "على الأقل رقم واحد",
      "orLoginWithSocial": "أو تسجيل الدخول عبر الشبكات الاجتماعية",
      "loginInstagram": "تسجيل الدخول عبر إنستجرام",
      "loginApple": "تسجيل الدخول عبر آبل",
      "continue": "استمرار",
       "loginWithGoogle": "تسجيل الدخول باستخدام Google",
      "loginWithFacebook": "تسجيل الدخول باستخدام Facebook",
      "fillFieldsWithValidInfo": "يرجى ملء جميع الحقول بمعلومات صحيحة.",
      "passwordRequirements": "يجب أن تحتوي كلمة المرور على أحرف كبيرة وصغيرة وأرقام.",
      "registrationFailed": "فشل التسجيل. حاول مرة أخرى.",
      "generalError": "حدث خطأ. حاول مرة أخرى لاحقاً.",
      "loginSuccessful": "تم تسجيل الدخول بنجاح!",
      "favorites": "المفضلة",
      "new_arrival": "الواصلون الجدد"


      // أضف المزيد من الترجمات هنا
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ar", // اللغة الافتراضية
  interpolation: {
    escapeValue: false
  }
});

export default i18n;