import './Product.css';
import img8 from '../images/IMG-20240723-WA0008.jpg';
import img from "../images/IMG-20240723-WA0006.jpg";
import img2 from "../images/IMG-20240723-WA0008.jpg";
import img3 from "../images/IMG-20240723-WA0013.jpg";
import img4 from "../images/IMG-20240723-WA0018.jpg";
import img5 from "../images/IMG-20240723-WA0021.jpg";
import img6 from "../images/IMG-20240723-WA0022.jpg";
import img7 from "../images/IMG-20240723-WA0023.jpg";
import img9 from "../images/IMG-20240723-WA0007.jpg";
import img10 from "../images/IMG-20240723-WA0008.jpg";
import img11 from "../images/IMG-20240723-WA0010.jpg";
import { useState, useEffect } from 'react';
import { faBox, faCheck, faChevronLeft, faChevronRight, faExchangeAlt, faHeart, faRocket, faShare, faStar, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { postcart, postReviews } from '../Redux/API';
import { addFavorite } from "../Redux/API";
import { useDispatch } from 'react-redux';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TelegramShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    RedditIcon,
} from 'react-share';
import StarRatings from 'react-star-ratings';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Product() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const productFromState = useSelector(state => state?.products?.product);
    
    const [product, setProduct] = useState(() => {
        try {
            const savedProduct = localStorage.getItem('product');
            return savedProduct ? JSON.parse(savedProduct) : productFromState || {};
        } catch (error) {
            console.error("Error parsing localStorage data:", error);
            return productFromState || {};
        }
    });

    useEffect(() => {
        if (productFromState && Object.keys(productFromState).length > 0) {
            try {
                localStorage.setItem('product', JSON.stringify(productFromState));
                setProduct(productFromState);
            } catch (error) {
                console.error("Error saving to localStorage:", error);
            }
        }
    }, [productFromState]);

    const quantity = product?.product_varients?.[0]?.quantity || 0;

    const product_reviews = useSelector(state => state.Data.product_reviews);
    const rating = product_reviews?.data?.data?.reviews_avg;
    const ratings = product_reviews?.data?.data?.ratings;

    const [selectedItem, setSelectedItem] = useState(product?.main_image || []);
    const [activ, setActiv] = useState(0);

    function handelImgeClik(image, index){
        setSelectedItem(image);
        setActiv(index);
    }

    const increment = () => {
        setCounter(prevCounter => (prevCounter < quantity ? prevCounter + 1 : prevCounter));
    };

    const decrement = () => {
        setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 0));
    };

    const colors = [{ id: 1, img: img8 }, { id: 2, img: img8 }, { id: 3, img: img2 }];
    const sizes = [{ id: 1, size: 'L' }, { id: 2, size: 'M' }, { id: 3, size: 'XL' }, { id: 4, size: 'XXL' }, { id: 5, size: 'XXL' }];

    const [selectedColor, setSelectedColor] = useState(colors[0]?.id);
    const [selectedSize, setSelectedSize] = useState(sizes[0]?.id);
    const [counter, setCounter] = useState(0);

    const handleAddToBasket = () => {
        if (counter > 0) {
            // postcart({
            //     color: selectedColor,
            //     size: selectedSize,
            //     quantity: counter
            // });
        }
    };

    const [likedIds, setLikedIds] = useState(product?.favorite ? [product?.id] : []);

    const toggleLike = (id) => {
        if (likedIds.includes(id)) {
            setLikedIds(prev => prev.filter(i => i !== id));
        } else {
            setLikedIds(prev => [...prev, id]);
            dispatch(addFavorite({ product_id: id }));
        }
    };

    const [showShareButtons, setShowShareButtons] = useState(false);
    const toggleShareButtons = () => {
        setShowShareButtons(prev => !prev);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(product?.url || '').then(() => {
            alert('Product link copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const [showRating, setShowRating] = useState(false);
    const FshowRating = () => {
        setShowRating(!showRating);
        setTimeout(() => setShowRating(false), 6000);
    }

    const [rat, setRat] = useState(0);
    const changeRating = (newRating) => {
        setRat(newRating);
        dispatch(postReviews({ product_id: product?.id, rating: newRating }));
    };

    return (
        <div className="Product">
            <Header />
            <div className={`container_Product container`}>
                <div className='row justify-content-between'>
                    <div className="box_image col-12 col-md-5 col-lg-4">
                        <div>
                            <img className='img' src={selectedItem} />
                            <i className={`font-size2 i ${likedIds.includes(product?.id) ? "liked" : ""}`}
                                onClick={() => toggleLike(product?.id)}
                                style={{ color: likedIds.includes(product?.id) ? 'red' : 'white' }}
                            >
                                <FontAwesomeIcon icon={faHeart} />
                            </i>
                        </div>
                        <div className="div_counter">
                            <div className="div_images">
                                {product?.images?.length > 0 && product.images.map((image, index) => (
                                    <img src={image?.image} key={index} className={activ === index ? 'activ' : ''} onClick={() => handelImgeClik(image?.image, index)} />
                                ))}
                            </div>
                        </div> 
                    </div>
                    <div className={`box_data col-12 col-md-5 col-lg-3`}>
                    <p>{product?.name}</p>
                     <p>{product?.description}</p>
                     <p className='price'>{product?.new_price} KD <span> <span></span>{product?.old_price} KD</span></p>
                     <div className='check'>
                         <i className='i'><FontAwesomeIcon icon={faCheck} /></i>
                         <p>{product?.status}</p>
                     </div>
                        <div className='Color'>
                            <p>Color</p>
                            {/* <div className='imgs'>
                                {colors?.map((color, index) => (
                                    <img 
                                        src={color?.img} 
                                        key={index} 
                                        className={selectedColor === color?.id ? 'selected' : ''} 
                                        onClick={() => setSelectedColor(color?.id)} 
                                    />
                                ))}
                            </div> */}
                        </div>
                        <div className='Size'>
                            <p>Size</p>
                            {/* <div>
                                {sizes?.map((size, index) => (
                                    <p 
                                        key={index} 
                                        className={selectedSize === size?.id ? 'selected' : ''} 
                                        onClick={() => setSelectedSize(size?.id)}
                                    >
                                        {size?.size}
                                    </p>
                                ))}
                            </div> */}
                        </div>
                        <div className={`Quantity`}>
                            <p>Quantity</p>
                            <div className="counter">
                                <span className='span1' onClick={decrement}>-</span>
                                <span className='span3'>{counter}</span>
                                <span className='span2' onClick={increment}>+</span>
                            </div>
                        </div>
                        <div className={`add`}>
                            <p 
                                className={counter > 0 ? 'active' : ''} 
                                onClick={handleAddToBasket}
                            >
                                Add to Basket
                            </p>
                            <p 
                                className={counter > 0 ? 'active' : ''} 
                                onClick={handleAddToBasket}
                            >
                                Buy Now
                            </p>
                        </div>
                    </div>

                    <div className={`box_brand col-12 col-lg-3 mt-md-5 mt-sm-4 justify-content-between`}>
                        <div>
                            <i className='i' onClick={toggleShareButtons}>
                                <FontAwesomeIcon icon={faShare} />
                            </i>
                            {showShareButtons && (
                                <div className="share-buttons">
                                    <FacebookShareButton className='button' url={product?.url || ''}>
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton className='button' url={product?.url || ''}>
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                    <WhatsappShareButton className='button' url={product?.url || ''}>
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <LinkedinShareButton className='button' url={product?.url || ''}>
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                    <PinterestShareButton className='button' url={product?.url || ''}>
                                        <PinterestIcon size={32} round />
                                    </PinterestShareButton>
                                    <TelegramShareButton className='button' url={product?.url || ''}>
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                </div>
                            )}
                        </div>
                        <div className='col-5 col-lg-12'>
                            <p>Store : {product?.brand}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`container_data container`}>
                <div className='row justify-content-between g-0'>
                    <div className='details col-12 col-lg-5'>
                        <p className='detailsP'>Product Details</p>
                        <p className='detailsP'>Material</p>
                        <p className='detailsP2'>
                            <span>Material</span>
                            <span>{product?.product_varients?.[0]?.material}</span>
                        </p>
                        <p className='detailsP2'>
                            <span>Material Composition</span>
                            <span>{product?.product_varients?.[0]?.material_composition}</span>
                        </p>
                        <p className='detailsP'>Warranty & Care</p>
                        <p className='detailsP2'>
                            <span>Care Instructions</span>
                            <span>{product?.product_varients?.[0]?.care_instructions}</span>
                        </p>
                        <p className='detailsP'>General Specifications</p>
                        <p className='detailsP2'>
                            <span>Nature's Comfort</span>
                            <span>{product?.product_varients?.[0]?.nature_comfort}</span>
                        </p>
                        <p className='detailsP2'>
                            <span>Occasion</span>
                            <span>{product?.product_varients?.[0]?.occasion}</span>
                        </p>
                        <p className='detailsP2'>
                            <span>Model</span>
                            <span>M</span>
                        </p>
                    </div>
                    <div className='Shipping_Reviews col-12 col-lg-6'>
                        <div className='Shipping'>
                            <p className='ShippingP'>Shipping</p>
                            {product?.shippings?.length > 0 && product.shippings.map((shipping, index) => (
                                <p className='ShippingP' key={index}>
                                    <i className='i'><FontAwesomeIcon icon={faTruck} /></i>
                                    {shipping?.key}: <span>{shipping?.value}</span>
                                </p>
                            ))}
                        </div>
                        <div className='Reviews'>
                            <p className='pr'>Reviews</p>
                            <div className='ReviewsD justify-content-between align-items-center'>
                                <div>
                                    <p>Average Rating</p>
                                    <p className='p5'>{rating}</p>
                                    <p>Out of <span>{rating}</span> stars</p>
                                </div>
                                <div className="star-container">
                                    {[
                                        { stars: 5, count: ratings?.five_star },
                                        { stars: 4, count: ratings?.four_star },
                                        { stars: 3, count: ratings?.three_star },
                                        { stars: 2, count: ratings?.two_star },
                                        { stars: 1, count: ratings?.one_star },
                                    ].map((ratingItem, index) => (
                                        <div
                                            key={index}
                                            className={`star-row ${rating === ratingItem?.stars ? 'highlight-stars' : ''}`}
                                        >
                                            <div className="stars">
                                                {Array.from({ length: ratingItem?.stars }).map((_, i) => (
                                                    <FontAwesomeIcon key={i} icon={faStar} className='mx-1' />
                                                ))}
                                            </div>
                                            <div className="line"></div>
                                            <span className="star-count">{ratingItem?.count}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='button mt-4'>
                                <p className='button1'>What do you think about this product?</p>
                                <p className='button2' onClick={FshowRating}>Write a Review</p>
                            </div>
                            {showRating && (
                                <div className='StarRatings font-size2'>
                                    <StarRatings
                                        rating={rat}
                                        starRatedColor="gold"
                                        changeRating={changeRating}
                                        numberOfStars={5}
                                        name='rating'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='Customer'><span>20</span> Customer reviews and <span>8</span> Customer ratings</div>
            </div>
            <Footer />
        </div>
    );
}

export default Product;
// import './Product.css';
// import img8 from '../images/IMG-20240723-WA0008.jpg';
// import img from "../images/IMG-20240723-WA0006.jpg";
// import img2 from "../images/IMG-20240723-WA0008.jpg";
// import img3 from "../images/IMG-20240723-WA0013.jpg";
// import img4 from "../images/IMG-20240723-WA0018.jpg";
// import img5 from "../images/IMG-20240723-WA0021.jpg";
// import img6 from "../images/IMG-20240723-WA0022.jpg";
// import img7 from "../images/IMG-20240723-WA0023.jpg";
// import img9 from "../images/IMG-20240723-WA0007.jpg";
// import img10 from "../images/IMG-20240723-WA0008.jpg";
// import img11 from "../images/IMG-20240723-WA0010.jpg";
// import { useState, useEffect } from 'react';
// import { faBox, faCheck, faChevronLeft, faChevronRight, faExchangeAlt, faHeart, faRocket, faShare, faStar, faTruck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { postcart, postReviews } from '../Redux/API';
// import { addFavorite } from "../Redux/API";
// import { useDispatch } from 'react-redux';
// import {  FacebookShareButton,
//     TwitterShareButton,
//     WhatsappShareButton,
//     LinkedinShareButton,
//     PinterestShareButton,
//     TelegramShareButton,
//     RedditShareButton,
//     FacebookIcon,
//     TwitterIcon,
//     WhatsappIcon,
//     LinkedinIcon,
//     PinterestIcon,
//     TelegramIcon,
//     RedditIcon,
// } from 'react-share';
// import StarRatings from 'react-star-ratings';


// import Header from '../Header/Header';
// import Footer from '../Footer/Footer';
// function Product() {

//     const dispatch = useDispatch();
//     const { t } = useTranslation();
    

//     // const [product, setProduct] = useSelector(state => state.products.product);

//   // استرجاع المنتج من state أو localStorage
//   const productFromState = useSelector(state => state?.products?.product);
  
//   const [product, setProduct] = useState(() => {
//     try {
//       // استعادة البيانات من localStorage عند تحميل الصفحة
//       const savedProduct = localStorage.getItem('product');
//       return savedProduct ? JSON.parse(savedProduct) : productFromState || {}; // إضافة شرط للتأكد من البيانات
//     } catch (error) {
//       console.error("Error parsing localStorage data:", error);
//       return productFromState || {}; // إذا كان هناك خطأ في التحليل، استخدام productFromState
//     }
//   });

//   useEffect(() => {
//     if (productFromState && Object.keys(productFromState).length > 0) {
//       try {
//         // تخزين المنتج في localStorage عند تغييره
//         localStorage.setItem('product', JSON.stringify(productFromState));
//         setProduct(productFromState); // تحديث state بالبيانات الجديدة من Redux
//       } catch (error) {
//         console.error("Error saving to localStorage:", error);
//       }
//     }
//   }, [productFromState]);
  

//     const quantity = product?.product_varients[0]?.quantity;

//     const product_reviews = useSelector(state => state.Data.product_reviews);
//     const rating = product_reviews?.data?.data?.reviews_avg
//     const ratings = product_reviews?.data?.data?.ratings



//     const [selectedItem, setSelectedItem] = useState(product?.main_image || []);

//     const [activ, setActiv] = useState(0);

//     function handelImgeClik(image, index){
//         setSelectedItem(image);
//         setActiv(index);
//     }



//     const increment = () => {
//     setCounter(prevCounter => {
//         if (prevCounter < quantity) {
//         return prevCounter + 1;
//         }
//         return prevCounter; 
//     });
//     };

//     const decrement = () => {
//     setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 0));
//     };

//     const colors = [{id: 1, img: img8}, {id: 2, img: img8}, {id: 3, img: img2}];
//     const sizes = [{id: 1, size:'L'}, {id: 2, size:'M'}, {id: 3, size:'XL'}, {id: 4, size:'XXL'}, {id: 5, size:'XXL'}];

//     const [selectedColor, setSelectedColor] = useState(colors[0].id);
//     const [selectedSize, setSelectedSize] = useState(sizes[0].id);
//     const [counter, setCounter] = useState(0);

//     const handleAddToBasket = () => {
//         if (counter > 0) {
//             // postcart({
//             //     color: selectedColor,
//             //     size: selectedSize,
//             //     quantity: counter
//             // });
//         }
//     };


//     const [likedIds, setLikedIds] = useState(product?.favorite ? [product.id] : []);

//     const toggleLike = (id) => {
//         if (likedIds.includes(id)) {
//             setLikedIds(prev => prev.filter(i => i !== id)); // إزالة الـ id من القائمة إذا كان موجودًا
//         } else {
//             setLikedIds(prev => [...prev, id]); // إضافة الـ id إلى القائمة إذا لم يكن موجودًا
//             dispatch(addFavorite({ product_id: id }));
//         }
//     };


//     const [showShareButtons, setShowShareButtons] = useState(false);
//     const toggleShareButtons = () => {
//         setShowShareButtons(prev => !prev);
//     };

//     const copyToClipboard = () => {
//         navigator.clipboard.writeText(product?.url || '').then(() => {
//             alert('Product link copied to clipboard!');
//         }).catch(err => {
//             console.error('Failed to copy: ', err);
//         });
//     };


//     const [showRating, setShowRating] = useState(false);
//     const FshowRating = () => {
//         setShowRating(!showRating); // إظهار الباكيج
//         setTimeout(() => setShowRating(false), 6000); // إخفاء الباكيج بعد 3 ثواني
//     }

//     const [rat, setRat] = useState(0);
//     const changeRating = (newRating) => {
//       setRat(newRating);
//       dispatch(postReviews({product_id: product.id, rating: newRating}));

//     };
  
// return (
//     <div className="Product">
//         <Header />
//         {/* <div className='headerB container'>
//             <p>
//                 <span>Home</span>
//                 <i ><FontAwesomeIcon icon={faChevronRight}/></i>
//             </p>
//             <p>
//                 <span>Men</span>
//                 <i ><FontAwesomeIcon icon={faChevronRight}/></i>
//             </p>
//             <p>
//                 <span>T-shirts</span>
//             </p>
//         </div> */}
//         <div className={`container_Product container`}>
//             <div className='row justify-content-between'>
//                 <div className="box_image col-12 col-md-5 col-lg-4">
//                     <div>
//                         <img className='img' src={selectedItem} />
//                         <i className={`font-size2 i ${likedIds.includes(product?.id) ? "liked" : ""}`}
//                             onClick={() => toggleLike(product?.id)}
//                             style={{ color: likedIds?.includes(product?.id) ? 'red' : 'white' }}
//                         >
//                             <FontAwesomeIcon icon={faHeart} />
//                         </i>
//                     </div>
//                     <div className="div_counter">
//                         <div className="div_images">
//                             {product?.images?.map((image, index) => (
//                                 <img src={image?.image} key={index} className={activ === index ? 'activ' : ''} onClick={() => handelImgeClik(image?.image, index)}/>
//                             ))}
//                         </div>
//                     </div> 
//                 </div>
//                 <div className={`box_data col-12 col-md-5 col-lg-3`}>
//                     <p>{product?.name}</p>
//                     <p>{product?.description}</p>
//                     <p className='price'>{product?.new_price} KD <span> <span></span>{product?.old_price} KD</span></p>
//                     <div className='check'>
//                         <i className='i'><FontAwesomeIcon icon={faCheck} /></i>
//                         <p>{product?.status}</p>
//                     </div>
//                     <div className='Color'>
//                 <p>Color</p>
//                 <div className='imgs'>
//                     {colors?.map((color, index) => (
//                         <img 
//                             src={color.img} 
//                             key={index} 
//                             className={selectedColor === color?.id ? 'selected' : ''} 
//                             onClick={() => setSelectedColor(color?.id)} 
//                         />
//                     ))}
//                 </div>
//             </div>
//             <div className='Size'>
//                 <p>Size</p>
//                 <div>
//                     {sizes.map((size, index) => (
//                         <p 
//                             key={index} 
//                             className={selectedSize === size.id ? 'selected' : ''} 
//                             onClick={() => setSelectedSize(size?.id)}
//                         >
//                             {size?.size}
//                         </p>
//                     ))}
//                 </div>
//             </div>
//             <div className={`Quantity`}>
//                 <p>Quantity</p>
//                 <div className="counter">
//                     <span className='span1' onClick={decrement}>-</span>
//                     <span className='span3'>{counter}</span>
//                     <span className='span2' onClick={increment}>+</span>
//                 </div>
//             </div>
//             <div className={`add`}>
//                 <p 
//                     className={counter > 0 ? 'active' : ''} 
//                     onClick={handleAddToBasket}
//                 >
//                     Add to Basket
//                 </p>
//                 <p 
//                     className={counter > 0 ? 'active' : ''} 
//                     onClick={handleAddToBasket}
//                 >
//                     Buy Now
//                 </p>
//             </div>
//                 </div>
                
//                 <div className={`box_brand col-12 col-lg-3 mt-md-5  mt-sm-4 justify-content-between`}>
//                     <div>
//                         <i className='i' onClick={(copyToClipboard, toggleShareButtons)}>
//                             <FontAwesomeIcon icon={faShare} />
//                         </i>
//                         {showShareButtons && (
//                             <div className="share-buttons">
//                                 <FacebookShareButton className='button' url={product?.url || ''}>
//                                     <FacebookIcon size={32} round />
//                                 </FacebookShareButton>
//                                 <TwitterShareButton className='button' url={product?.url || ''}>
//                                     <TwitterIcon size={32} round />
//                                 </TwitterShareButton>
//                                 <WhatsappShareButton className='button' url={product?.url || ''}>
//                                     <WhatsappIcon size={32} round />
//                                 </WhatsappShareButton>
//                                 <LinkedinShareButton className='button' url={product?.url || ''}>
//                                     <LinkedinIcon size={32} round />
//                                 </LinkedinShareButton>
//                                 <PinterestShareButton className='button' url={product?.url || ''}>
//                                     <PinterestIcon size={32} round />
//                                 </PinterestShareButton>
//                                 <TelegramShareButton className='button' url={product?.url || ''}>
//                                     <TelegramIcon size={32} round />
//                                 </TelegramShareButton>
//                             </div>
//                         )}
//                     </div>
//                     <div className='col-5 col-lg-12'>
//                         <p>Store : {product?.brand}</p>
//                     </div>
//                 </div>

//             </div>
//         </div>
//         <div className={`container_data container`}>
//             <div className='row justify-content-between g-0'>
//             <div className='details col-12 col-lg-5'>
//                 <p className='detailsP'>product Details</p>
//                 <p className='detailsP'>Material</p>
//                 <p className='detailsP2'>
//                     <span>Materil</span>
//                     <span>{product?.product_varients[0]?.material}</span>
//                 </p>
//                 <p className='detailsP2'>
//                     <span>Materil Composition</span>
//                     <span>{product?.product_varients[0]?.material_composition}</span>
//                 </p>
//                 <p className='detailsP'>Warranty & Car</p>
//                 <p className='detailsP2'>
//                     <span>Car instructions</span>
//                     <span>{product?.product_varients[0]?.care_instructions}</span>
//                 </p>
//                 <p className='detailsP'>Generral Specifications</p>
//                 <p className='detailsP2'>
//                     <span>Nature's Comfort</span>
//                     <span>{product?.product_varients[0]?.nature_comfort}</span>
//                 </p>
//                 <p className='detailsP2'>
//                     <span>Occasion</span>
//                     <span>{product?.product_varients[0]?.occasion}</span>
//                 </p>
//                 <p className='detailsP2'>
//                     <span>model</span>
//                     <span>M</span>
//                 </p>
//             </div>
//             <div className='Shipping_Reviews col-12 col-lg-6'>
//                 <div className='Shipping'>
//                     <p className='ShippingP'>Shipping</p>
//                     <p className='ShippingP'>
//                         <i className='i'><FontAwesomeIcon icon={faTruck} /></i>
//                         {product?.shippings[0]?.key}: <span>{product?.shippings[0]?.value}</span>
//                     </p>
//                     <p className='ShippingP'>
//                         <i className='i'><FontAwesomeIcon icon={faRocket} /></i>
//                         {product?.shippings[1]?.key}: <span>{product?.shippings[1]?.value}</span>
//                     </p>
//                     <p className='ShippingP'>
//                         <i className='i'><FontAwesomeIcon icon={faBox} /></i>
//                         {product?.shippings[2]?.key}: <span>{product?.shippings[2]?.value}</span>
//                     </p>
//                 </div>
//                 <div className='Reviews'>
//                     <p className='pr'>Reviews</p>
//                     <div className='ReviewsD justify-content-between align-items-center'>
//                         <div>
//                             <p>Aerage Rating</p>
//                             <p className='p5'>{rating}</p>
//                             <p>Out of<span>{rating}</span> stars</p>
//                         </div>
//                         <div className="star-container">
//                             {[
//                                 { stars: 5, count: ratings?.five_star },
//                                 { stars: 4, count: ratings?.four_star },
//                                 { stars: 3, count: ratings?.three_star },
//                                 { stars: 2, count: ratings?.two_star },
//                                 { stars: 1, count: ratings?.one_star },
//                             ].map((ratingItem, index) => (
//                                 <div
//                                     key={index}
//                                     className={`star-row ${rating === ratingItem?.stars ? 'highlight-stars' : ''}`}
//                                 >
//                                     <div className="stars">
//                                         {Array.from({ length: ratingItem?.stars }).map((_, i) => (
//                                             <FontAwesomeIcon key={i} icon={faStar} className='mx-1' />
//                                         ))}
//                                     </div>
//                                     <div className="line"></div>
//                                     <span className="star-count">{ratingItem?.count}</span>
//                                 </div>
//                             ))}
//                         </div>

//                     </div>
//                     <div className='button mt-4'>
//                         <p className='button1'>What do you think about this product ?</p>
//                         <p className='button2' onClick={FshowRating}>Write a Review</p>
//                     </div>
//                     {showRating && (
//                     <div className='StarRatings  font-size2'>
//                         <StarRatings
//                             rating={rat}
//                             starRatedColor="gold"
//                             changeRating={changeRating}
//                             numberOfStars={5}
//                             name='rating'
//                         />
//                     </div>
//                     )}
//                 </div>
//             </div>
//             </div>
//         <div className='Customer'><span>20</span> Customer reviews and <span>8</span> Customer ratings</div>
//         </div>
//         <Footer />
//     </div>
//   );
// }

// export default Product;
