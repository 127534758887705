import React, { useState, useCallback } from 'react';
import { faHeart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import img9 from '../images/IMG-20240723-WA0008.jpg';
import img8 from '../images/IMG-20240723-WA0008.jpg';
import img from '../images/IMG-20240723-WA0006.jpg';
import img2 from '../images/IMG-20240723-WA0008.jpg';
import img3 from '../images/IMG-20240723-WA0013.jpg';
import img4 from '../images/IMG-20240723-WA0018.jpg';
import img5 from '../images/IMG-20240723-WA0021.jpg';
import img6 from '../images/IMG-20240723-WA0022.jpg';
import img7 from '../images/IMG-20240723-WA0023.jpg';
import img10 from '../images/IMG-20240723-WA0008.jpg';
import img11 from '../images/IMG-20240723-WA0010.jpg';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './NewArriva.css'
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { new_arrivals } from "../Redux/API";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
function NewArriva() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(new_arrivals())
    }, [])

    const [likedIndexes, setLikedIndexes] = useState([]);

    const toggleLike = (index) => {
      setLikedIndexes(prev => 
        prev.includes(index) 
          ? prev.filter(i => i !== index) // Remove index if already liked
          : [...prev, index] // Add index if not liked
      );
    };

    const new_arrival = useSelector(state => state.Data.new_arrivals);
    const loadingNew_arrivals = useSelector(state => state.Data.loadingNew_arrivals);
    const images = new_arrival?.data?.data || [];


    const pepoles = [
        { src: img, title: 'Ali', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img2, title: 'Mohamed', p: "AMERIX BIO TIN 60 PIECES hello mohamed ahmed", price: "469.000" },
        { src: img3, title: 'Ahmed', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img3, title: 'Ahmed', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img3, title: 'Ahmed', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
        { src: img3, title: 'Ahmed', p: "AMERIX BIO TIN 60 PIECES", price: "469.000" },
    ];

    
    return (
        <>
            <Header />
            <div className='container offers'>
            {loadingNew_arrivals && <Loader />}

                <div className='cont row justify-content-between g-0'>
                <h3 className='title'>{t('new_arrival')}</h3>
                <div className='wishlist col border-0'>
                        <div className="gallery1 container">
                            <div className="row_prodect row  align-items-center justify-content-around g-0 border-0">
                            {images.map((image, index) => (
                                    <div key={index} className="box col-5 col-sm-4 px-sm-2  col-md-4 px-md-3   col-lg-3 px-lg-3 mx-lg-0  col-xl-2 mx-xl-1   px-xl-0   text-center g-0 mb-3">
                                     <div className="border_box">
                                        <div className="padding_box">
                                        <i className="font-size2" 
                                            onClick={() => toggleLike(index)} 
                                            style={{ color: likedIndexes.includes(index) ? 'red' : 'white' }}
                                        >
                                            <FontAwesomeIcon icon={faHeart} />
                                        </i>
                                        {image?.discount && <p className="discount">Off {image?.discount}</p>}

                                        <Link to={"/Product"}>
                                            <img src={image.main_image} alt={`img-${index}`} />
                                        </Link>
                                            <p className="discraptoin">{image?.description}</p>
                                            <div className="price align-items-center">
                                            <p>{image?.new_price}</p>
                                            {image?.old_price && <p className="old_price">{image?.old_price}</p>}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* <div className="row_prodect2 row align-items-center justify-content-around g-0">
                                {pepoles.map((image, index) => (
                                    <div key={index} className="box box_cont col-5 col-sm-4 px-sm-2  col-md-4 px-md-3   col-lg-3 px-lg-3 mx-lg-0  col-xl-2 mx-xl-1   px-xl-0   text-center g-0 mb-3">

                                        <div className='group mb-3'>
                                            <div className="border_box">
                                                <div className="padding_box">
                                                    <i className='font-size2'><FontAwesomeIcon icon={faTimes} /></i>
                                                    <Link to={"/Product"}>
                                                    <img src={image.src} alt={`img-${index}`} />
                                                    </Link>                                                 
                                                </div>
                                            </div>
                                            <div className="price_cont align-items-center ">
                                                <p>{image.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </div>

                
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NewArriva;