import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const getLanguageFromStorage = () => {
  return localStorage.getItem('language') || 'en'; // إذا لم توجد لغة في التخزين، سيكون الافتراضي 'en'
};
axios.interceptors.request.use((config) => {
  const language = getLanguageFromStorage();
  config.headers['Accept-Language'] = language;
  return config;
}, (error) => {
  return Promise.reject(error);
});

// هتجيب البيانات المخزنة من الـ localStorage بشكل مباشر
const getLocalStorage = window.localStorage.getItem('UserInfo');

// هتعمل parse للبيانات لو موجودة
const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;

// استخراج التوكن من البيانات المخزنة

const token = valueLocalStorage && valueLocalStorage.data 
  ? (valueLocalStorage?.data?.token || 
     (valueLocalStorage?.data?.data && valueLocalStorage.data.data.user 
      ? valueLocalStorage?.data?.data?.user?.token 
      : null)) 
  : null;
  
console.log(token)
export const addUser = createAsyncThunk('user/add', async ({ first_name, last_name, password, email, role }, thunkAPI) => {
  console.error(first_name, last_name, password, email, role );
 
  try {
    const response = await axios.post('https://api.ashtiri.com/api/register', { first_name, last_name, password, email, role });
    console.error(response.data);

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);

    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const verification = createAsyncThunk('user/verification', async ({email, verification_code }, thunkAPI) => {
  try {
    console.log(email, verification_code)
    const response = await axios.post('https://api.ashtiri.com/api/verify-email', {email, verification_code });
    console.error(response.data);

    return response.data;
  } catch (error) {
      if (error.response) {
        console.error("Server responded with:", error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        console.error("Error:", error.message);
        return thunkAPI.rejectWithValue(error.message);
      }
  }
});

export const login = createAsyncThunk('user/login', async ({ email, password }, thunkAPI) => {
  try {
    const response = await axios.post('https://api.ashtiri.com/api/login', { email, password });
    if (response.data.success === false) {
      // إذا كان الرد غير ناجح
    console.log(response.data);
      return thunkAPI.rejectWithValue(response.data.message);
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data.message || 'Login failed.');
    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue('An error occurred. Please try again later.');
    }
  }
});
// export const login = createAsyncThunk('user/login', async ({email, password}, thunkAPI) => {
//   try {
//     console.log(email, password)

//     const response = await axios.post('https://api.ashtiri.com/api/login', {email, password });
//     console.error(response.data);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       console.error("Server responded with:", error.response.data);
//       return thunkAPI.rejectWithValue(error.response.data);
//     } else {
//       console.error("Error:", error.message);
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// });


export const sendReset = createAsyncThunk('user/sendReset', async ({email}, thunkAPI) => {
  try {
    console.log(email)

    const response = await axios.post('https://api.ashtiri.com/api/send-reset-password-code', {email });
    return { data: response.data, status: response.status };

  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const verifyReset = createAsyncThunk('user/verifyReset', async ({email, verification_code}, thunkAPI) => {
  try {
    console.log(email, verification_code)

    const response = await axios.post('https://api.ashtiri.com/api/verify-reset-password-code', {email, verification_code });
    console.log(response.data)

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async ({email, password, password_confirmation}, thunkAPI) => {
  try {
    console.log(email, password, password_confirmation)

    const response = await axios.post('https://api.ashtiri.com/api/reset-password', {email, password, password_confirmation});
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const category = createAsyncThunk('user/category', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/category');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const brands = createAsyncThunk('user/brands', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/brands');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const recommended = createAsyncThunk('user/recommended', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/recommended-for-you');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const offers = createAsyncThunk('user/offers', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/offers');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const new_arrivals = createAsyncThunk('user/new_arrivals', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/new-arrivals');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const best_of = createAsyncThunk('user/best_of', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/best-of');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const store = createAsyncThunk('user/store', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/store');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const influencer = createAsyncThunk('user/influencer', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/influencer');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const ads = createAsyncThunk('user/ads', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/ads');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const latest = createAsyncThunk('user/latest', async () => {
  try {
    const response = await axios.get('https://api.ashtiri.com/api/ads/latest');
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

console.log(document.cookie);  // هتطبع الكوكيز الموجودة على المتصفح

// axios.defaults.withCredentials = true;

// console.log(axios);
export const addFavorite = createAsyncThunk('user/addFavorite', async ({product_id}) => {
  console.error(product_id);
  try {
    // const response = await axios.post('https://api.ashtiri.com/api/add-product-to-favorite', {product_id});
    const response = await axios.post('https://api.ashtiri.com/api/add-product-to-favorite', {product_id}, { withCredentials: true } );
    console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhkkyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyhddddddddddDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');

    console.log(response.data);

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const favorites = createAsyncThunk('user/favorites', async ({user_id}) => {

  try {
    const response = await axios.get(`https://api.ashtiri.com/api/favorites`,{user_id});
    console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhddddddddddDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const postcart = createAsyncThunk('user/postcart', async () => {

  try {
    const response = await axios.post('https://api.ashtiri.com/api/cart');
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const product_reviews = createAsyncThunk('user/product_reviews', async (product_id) => {
  try {
    const response = await axios.get(`https://api.ashtiri.com/api/review/product-reviews/${product_id}`);
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});


export const postReviews = createAsyncThunk('user/postReviews', async ({product_id, rating}) => {
  try {
    // إعداد الهيدر إذا كان التوكن موجود
    console.log(token)
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    // إرسال الطلب مع التوكن المرفق في الهيدر
    const response = await axios.post('https://api.ashtiri.com/api/review/add-review',{product_id, rating}, config);
    
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});


export const summary = createAsyncThunk('user/summary', async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.get('https://api.ashtiri.com/api/points/summary', config);
    
    console.log(response.data);
    return { data: response.data, status: response.status };
    } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);

    } else {
      console.error("Error:", error.message);
    }
  }
});


export const get_user_return = createAsyncThunk('user/get_user_return', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.get('https://api.ashtiri.com/api/get-user-return', config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);

      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
      
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const get_user_exchange = createAsyncThunk('user/get_user_exchange', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    // إرسال الطلب مع التوكن المرفق في الهيدر
    const response = await axios.get('https://api.ashtiri.com/api/get-user-exchange', config);
    
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const myProfile = createAsyncThunk('user/myProfile', async (search, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.get(`https://api.ashtiri.com/api/profile?search=${search}`, config); // هنا بنبعت رقم الأوردر
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const add_wishlist = createAsyncThunk('user/add_wishlist', async ({id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.post(`https://api.ashtiri.com/api/wishlist/add/${id}`, {}, config);
    
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const remove_wishlist= createAsyncThunk('user/remove_wishlist', async ({id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };
    console.log(token)
    console.log(config)

    const response = await axios.post(`https://api.ashtiri.com/api/wishlist/remove/${id}`, {}, config);
    
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); 
    }
  }
});


export const unfollow = createAsyncThunk('user/unfollow', async ({id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.post(`https://api.ashtiri.com/api/influencers/unfollow/${id}`, {}, config);
    
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const return_orders = createAsyncThunk('user/return_orders', async ({order_id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.post(`https://api.ashtiri.com/api/orders/return`, {order_id}, config);
    
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});
export const exchange = createAsyncThunk('user/exchange', async ({order_id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.post(`https://api.ashtiri.com/api/exchange/request`, {order_id}, config);
    
    console.log(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});







export const searsh_brand = createAsyncThunk('user/searsh_brand', async (search, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', // إضافة التوكن فقط عند الحاجة
      }
    };

    // تأكد من أن search نص وليس كائن
    const response = await axios.get(`https://api.ashtiri.com/api/product?search=${search}`, config);
    console.log({ data: response.data, status: response.status });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const show_brand = createAsyncThunk('user/show_brand', async ({id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.get(`https://api.ashtiri.com/api/brands/${id}`,{}, config);

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});


export const sort_products = createAsyncThunk('user/sort_products', async (value, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    const response = await axios.get('https://api.ashtiri.com/api/sort-products',{sort: value}, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    // التحقق من الخطأ والرد المناسب
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

export const influencers_follow = createAsyncThunk('user/influencers_follow', async (follow, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    // إرسال الطلب مع التوكن المرفق في الهيدر
    const response = await axios.post(`https://api.ashtiri.com/api/influencers/follow/${follow}`,{}, config);

    return { data: response.data, status: response.status };
  } catch (error) {
    // التحقق من الخطأ والرد المناسب
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});


export const filter_data = createAsyncThunk('user/filter_data', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };

    // إرسال الطلب مع التوكن المرفق في الهيدر
    const response = await axios.get('https://api.ashtiri.com/api/filter-data', config);

    return { data: response.data, status: response.status };
  } catch (error) {
    // التحقق من الخطأ والرد المناسب
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});


export const sort_data = createAsyncThunk('user/sort_data', async (follow, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      }
    };

    const response = await axios.get('https://api.ashtiri.com/api/sort-data',{follow}, config);

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); 
    }
  }
});

export const filter_brducts = createAsyncThunk('user/filter_brducts', async (filters, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      },


      params: {
        brand_ids: filters.brands || [],
        sizes: filters.sizes || [],
        types: filters.categories || [],
        price_range: filters.prices || [],
        discount_range: filters.discounts || [],
        color_families: filters.colors || [],
      }
    };

    const response = await axios.get('https://api.ashtiri.com/api/products/filter', config);
    console.log({data: response.data, status: response.status})
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); 
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); 
    }
  }
});






export const ad_details = createAsyncThunk('user/ad_details', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
      }
    };
    console.log(id)
    console.log(token)
    // إرسال الطلب مع التوكن المرفق في الهيدر
    const response = await axios.get(`https://api.ashtiri.com/api/ad/${id}`, config);
    console.log({data: response.data})
    console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR')
    return { data: response.data, status: response.status };
  } catch (error) {
    // التحقق من الخطأ والرد المناسب
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});


export const category_data = createAsyncThunk('user/category_data', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
        'types[]': aut 
      }
    };
    
    const response = await axios.get('https://api.ashtiri.com/api/products/filter', config);
    console.log({data: response.data})
        console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR')
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
    }
  }
});

// export const category_data = createAsyncThunk('user/category_data', async (aut, { rejectWithValue }) => {
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}` // إضافة التوكن فقط عند الحاجة
//       }
//     };
//     // console.log(aut)
//     // console.log(token)
//     // إرسال الطلب مع التوكن المرفق في الهيدر
//     const response = await axios.get(`https://api.ashtiri.com/api/filter-data?category=${aut}`, config);
//     console.log({data: response.data})
//     // console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR')
//     return { data: response.data, status: response.status };
//   } catch (error) {
//     // التحقق من الخطأ والرد المناسب
//     if (error.response) {
//       console.error("Server responded with:", error.response.data);
//       return rejectWithValue(error.response.data); // إرجاع الخطأ من السيرفر
//     } else {
//       console.error("Error:", error.message);
//       return rejectWithValue(error.message); // إرجاع رسالة الخطأ العام
//     }
//   }
// });





export const API = createSlice({
  name: 'Data',
  initialState: {

    userData: null,
    login: null,
    verificationData: null,
    sendReset: null,
    verifyReset: null,
    resetPassword: null,
    category:null,
    brands:null,
    recommended:null,
    offers:null,
    new_arrivals:null,
    best_of:null,
    store:null,
    influencer:null,
    ads:null,
    latest:null,
    addFavorite:null,
    favorites:null,
    postcart:null,
    product_reviews:null,
    postReviews:null,
    summary:null,
    get_user_return:null,
    get_user_exchange:null,
    myProfile:null,
    add_wishlist:null,
    remove_wishlist:null,
    unfollow:null,
    return_orders:null,
    exchange:null,
    category_data:null,
    ad_details:null,



    searsh_brand:null,
    show_brand:null,
    sort_products:null,
    influencers_follow:null,
    filter_data:null,
    sort_data:null,
    filter_brducts:null,






    loadingAddUser: false,
    loadingVerification: false,
    loadingLogin:false,
    loadingSendReset: false,
    loadingVerifyReset: false,
    loadingresetPassword: false,
    loadingCategory: false,
    loadingBrands: false,
    loadingRecommended: false,
    loadingOffers: false,
    loadingNew_arrivals: false,
    loadingBest_of: false,
    loadingStore: false,
    loadingInfluencer: false,
    loadingAds: false,
    loadingLatest: false,
    loadingAddFavorite: false,
    loadingFavorites: false,
    loadingPostcart: false,
    loadingProduct_reviews: false,
    loadingPostReviews: false,
    loadingSummary: false,
    loadingGet_user_return: false,
    loadingGet_user_exchange: false,
    loadingMyProfile: false,
    loadingAdd_wishlist: false,
    loadingUnfollow: false,
    loadingRemove_wishlist: false,
    loadingReturn_orders: false,
    loadingExchange: false,
    loadingCategory_data: false,
    loadingAd_details: false,

    loadingSearsh_brand: false,
    loadingShow_brand: false,
    loadingSort_products: false,
    loadingInfluencers_follow: false,
    loadingFilter_data: false,
    loadingSort_data: false,
    loadingFilter_brducts: false,


    errorAddUser: null,
    errorVerification: null,
    errorLogin: null,
    errorSendReset: null,
    errorVerifyReset: null,
    errorresetPassword: null,
    errorCategory: null,
    errorBrands: null,
    errorRecommended: null,
    errorOffers: null,
    errorNew_arrivals: null,
    errorStore: null,
    errorInfluencer: null,
    errorAds: null,
    errorLatest: null,
    errorFavorites: null,
    errorPostcart: null,
    errorPostReviews: null,
    errorSummary: null,
    errorGet_user_return: null,
    errorGet_user_exchange: null,
    errorMyProfile: null,
    errorAdd_wishlist: null,
    errorUnfollow: null,
    errorRemove_wishlist: null,
    errorReturn_orders: null,
    errorExchange: null,
    errorCategory_data: null,
    errorAd_details: null,


    errorSearsh_brand: null,
    errorShow_brand: null,
    errorSort_products: null,
    errorInfluencers_follow: null,
    errorFilter_data: null,
    errorSort_data: null,
    errorFilter_brducts: null,
  },
  
  reducers: {
    addNumber: (state, action) => {
      state.number = action.payload.number;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(addUser.pending, (state) => {
      state.loadingAddUser = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.loadingAddUser = false;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.errorAddUser = action.payload;
      state.loadingAddUser = false;
    });

    builder.addCase(login.pending, (state) => {
      state.loadingLogin = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.login = action.payload;
      state.loadingLogin = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.errorLogin = action.payload
      // state.errorLogin = action.payload;
      state.loadingLogin = false;
    });

    builder.addCase(verification.pending, (state) => {
      state.loadingVerification = true;
    });
    builder.addCase(verification.fulfilled, (state, action) => {
      state.verificationData = action.payload;
      state.loadingVerification = false;
    });
    builder.addCase(verification.rejected, (state, action) => {
      state.errorVerification = action.payload;
      state.loadingVerification = false;
    });


    builder.addCase(sendReset.pending, (state) => {
      state.loadingSendReset = true;
    });
    builder.addCase(sendReset.fulfilled, (state, action) => {
      state.sendReset = action.payload;
      state.loadingSendReset = false;
    });
    builder.addCase(sendReset.rejected, (state, action) => {
      state.errorSendReset = action.payload;
      state.loadingSendReset = false;
    });

    // verifyReset
    builder.addCase(verifyReset.pending, (state) => {
      state.loadingVerifyReset = true;
    });
    builder.addCase(verifyReset.fulfilled, (state, action) => {
      state.verifyReset = action.payload;
      state.loadingVerifyReset = false;
    });
    builder.addCase(verifyReset.rejected, (state, action) => {
      state.errorVerifyReset = action.payload;
      state.loadingVerifyReset = false;
    });

    // resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.loadingresetPassword = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPassword = action.payload;
      state.loadingresetPassword = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.errorresetPassword = action.payload;
      state.loadingresetPassword = false;
    });

    // category
    builder.addCase(category.pending, (state) => {
      state.loadingCategory = true;
    });
    builder.addCase(category.fulfilled, (state, action) => {
      state.category = action.payload;
      state.loadingCategory = false;
    });
    builder.addCase(category.rejected, (state, action) => {
      state.errorCategory = action.payload;
      state.loadingCategory = false;
    });

    // brands
    builder.addCase(brands.pending, (state) => {
      state.loadingBrands = true;
    });
    builder.addCase(brands.fulfilled, (state, action) => {
      state.brands = action.payload;
      state.loadingBrands = false;
    });
    builder.addCase(brands.rejected, (state, action) => {
      state.errorBrands = action.payload;
      state.loadingBrands = false;
    });

    // recommended
    builder.addCase(recommended.pending, (state) => {
      state.loadingRecommended = true;
    });
    builder.addCase(recommended.fulfilled, (state, action) => {
      state.recommended = action.payload;
      state.loadingRecommended = false;
    });
    builder.addCase(recommended.rejected, (state, action) => {
      state.errorRecommended = action.payload;
      state.loadingRecommended = false;
    });

    // offers
    builder.addCase(offers.pending, (state) => {
      state.loadingOffers = true;
    });
    builder.addCase(offers.fulfilled, (state, action) => {
      state.offers = action.payload;
      state.loadingOffers = false;
    });
    builder.addCase(offers.rejected, (state, action) => {
      state.errorOffers = action.payload;
      state.loadingOffers = false;
    });

    // new_arrivals
    builder.addCase(new_arrivals.pending, (state) => {
      state.loadingNew_arrivals = true;
    });
    builder.addCase(new_arrivals.fulfilled, (state, action) => {
      state.new_arrivals = action.payload;
      state.loadingNew_arrivals = false;
    });
    builder.addCase(new_arrivals.rejected, (state, action) => {
      state.errorNew_arrivals = action.payload;
      state.loadingNew_arrivals = false;
    });

    // best_of
    builder.addCase(best_of.pending, (state) => {
      state.loadingBest_of = true;
    });
    builder.addCase(best_of.fulfilled, (state, action) => {
      state.best_of = action.payload;
      state.loadingBest_of = false;
    });
    builder.addCase(best_of.rejected, (state, action) => {
      state.errorBest_of = action.payload;
      state.loadingBest_of = false;
    });

    // store
    builder.addCase(store.pending, (state) => {
      state.loadingStore = true;
    });
    builder.addCase(store.fulfilled, (state, action) => {
      state.store = action.payload;
      state.loadingStore = false;
    });
    builder.addCase(store.rejected, (state, action) => {
      state.errorStore = action.payload;
      state.loadingStore = false;
    });

    // Influencer
    builder.addCase(influencer.pending, (state) => {
      state.loadingInfluencer = true;
    });
    builder.addCase(influencer.fulfilled, (state, action) => {
      state.influencer = action.payload;
      state.loadingInfluencer = false;
    });
    builder.addCase(influencer.rejected, (state, action) => {
      state.errorInfluencer = action.payload;
      state.loadingInfluencer = false;
    });

    // Ads
    builder.addCase(ads.pending, (state) => {
      state.loadingAds = true;
    });
    builder.addCase(ads.fulfilled, (state, action) => {
      state.ads = action.payload;
      state.loadingAds = false;
    });
    builder.addCase(ads.rejected, (state, action) => {
      state.errorAds = action.payload;
      state.loadingAds = false;
    });

    // latest
    builder.addCase(latest.pending, (state) => {
      state.loadingLatest = true;
    });
    builder.addCase(latest.fulfilled, (state, action) => {
      state.latest = action.payload;
      state.loadingLatest = false;
    });
    builder.addCase(latest.rejected, (state, action) => {
      state.errorLatest = action.payload;
      state.loadingLatest = false;
    });

    // addFavorite
    builder.addCase(addFavorite.pending, (state) => {
      state.loadingAddFavorite = true;
    });
    builder.addCase(addFavorite.fulfilled, (state, action) => {
      state.addFavorite = action.payload;
      state.loadingAddFavorite = false;
    });
    builder.addCase(addFavorite.rejected, (state, action) => {
      state.errorAddFavorite = action.payload;
      state.loadingAddFavorite = false;
    });

    // addFavorite
    builder.addCase(favorites.pending, (state) => {
      state.loadingFavorites = true;
    });
    builder.addCase(favorites.fulfilled, (state, action) => {
      state.favorites = action.payload;
      state.loadingFavorites = false;
    });
    builder.addCase(favorites.rejected, (state, action) => {
      state.errorFavorites = action.payload;
      state.loadingFavorites = false;
    });

    // postcart
    builder.addCase(postcart.pending, (state) => {
      state.loadingPostcart = true;
    });
    builder.addCase(postcart.fulfilled, (state, action) => {
      state.postcart = action.payload;
      state.loadingPostcart = false;
    });
    builder.addCase(postcart.rejected, (state, action) => {
      state.errorPostcart = action.payload;
      state.loadingPostcart = false;
    });

    // errorProduct_reviews
    builder.addCase(product_reviews.pending, (state) => {
      state.loadingProduct_reviews = true;
    });
    builder.addCase(product_reviews.fulfilled, (state, action) => {
      state.product_reviews = action.payload;
      state.loadingProduct_reviews = false;
    });
    builder.addCase(product_reviews.rejected, (state, action) => {
      state.errorProduct_reviews = action.payload;
      state.loadingProduct_reviews = false;
    });

    // errorProduct_reviews
    builder.addCase(postReviews.pending, (state) => {
      state.loadingPostReviews = true;
    });
    builder.addCase(postReviews.fulfilled, (state, action) => {
      state.postReviews = action.payload;
      state.loadingPostReviews = false;
    });
    builder.addCase(postReviews.rejected, (state, action) => {
      state.errorPostReviews = action.payload;
      state.loadingPostReviews = false;
    });

    // errorProduct_reviews
    builder.addCase(summary.pending, (state) => {
      state.loadingSummary = true;
    });
    builder.addCase(summary.fulfilled, (state, action) => {
      state.summary = action.payload;
      state.loadingSummary = false;
    });
    builder.addCase(summary.rejected, (state, action) => {
      state.errorPostReviews = action.payload;
      state.loadingSummary = false;
    });

    // get_user_return
    builder.addCase(get_user_return.pending, (state) => {
      state.loadingGet_user_return = true;
    });
    builder.addCase(get_user_return.fulfilled, (state, action) => {
      state.get_user_return = action.payload;
      state.loadingGet_user_return = false;
    });
    builder.addCase(get_user_return.rejected, (state, action) => {
      state.errorGet_user_return = action.payload;
      state.loadingGet_user_return = false;
    });

    // get_user_exchange
    builder.addCase(get_user_exchange.pending, (state) => {
      state.loadingGet_user_exchange = true;
    });
    builder.addCase(get_user_exchange.fulfilled, (state, action) => {
      state.get_user_exchange = action.payload;
      state.loadingGet_user_exchange = false;
    });
    builder.addCase(get_user_exchange.rejected, (state, action) => {
      state.errorGet_user_return = action.payload;
      state.loadingGet_user_exchange = false;
    });

    // myProfile
    builder.addCase(myProfile.pending, (state) => {
      state.loadingMyProfile = true;
    });
    builder.addCase(myProfile.fulfilled, (state, action) => {
      state.myProfile = action.payload;
      state.loadingMyProfile = false;
    });
    builder.addCase(myProfile.rejected, (state, action) => {
      state.errorMyProfile = action.payload;
      state.loadingMyProfile = false;
    });

    // add_wishlist
    builder.addCase(add_wishlist.pending, (state) => {
      state.loadingAdd_wishlist = true;
    });
    builder.addCase(add_wishlist.fulfilled, (state, action) => {
      state.add_wishlist = action.payload;
      state.loadingAdd_wishlist = false;
    });
    builder.addCase(add_wishlist.rejected, (state, action) => {
      state.errorAdd_wishlist = action.payload;
      state.loadingAdd_wishlist = false;
    });

    // searsh_brand
    builder.addCase(searsh_brand.pending, (state) => {
      state.loadingSearsh_brand = true;
    });
    builder.addCase(searsh_brand.fulfilled, (state, action) => {
      state.searsh_brand = action.payload;
      state.loadingSearsh_brand = false;
    });
    builder.addCase(searsh_brand.rejected, (state, action) => {
      state.errorSearsh_brand = action.payload;
      state.loadingSearsh_brand = false;
    });

    // show_brand
    builder.addCase(show_brand.pending, (state) => {
      state.loadingShow_brand = true;
    });
    builder.addCase(show_brand.fulfilled, (state, action) => {
      state.show_brand = action.payload;
      state.loadingShow_brand = false;
    });
    builder.addCase(show_brand.rejected, (state, action) => {
      state.errorShow_brand = action.payload;
      state.loadingShow_brand = false;
    });

    // sort_products
    builder.addCase(sort_products.pending, (state) => {
      state.loadingSort_products = true;
    });
    builder.addCase(sort_products.fulfilled, (state, action) => {
      state.sort_products = action.payload;
      state.loadingSort_products = false;
    });
    builder.addCase(sort_products.rejected, (state, action) => {
      state.errorSort_products = action.payload;
      state.loadingSort_products = false;
    });


    // influencers_follow
    builder.addCase(influencers_follow.pending, (state) => {
      state.loadingInfluencers_follow = true;
    });
    builder.addCase(influencers_follow.fulfilled, (state, action) => {
      state.influencers_follow = action.payload;
      state.loadingInfluencers_follow = false;
    });
    builder.addCase(influencers_follow.rejected, (state, action) => {
      state.errorInfluencers_follow  = action.payload;
      state.loadingInfluencers_follow = false;
    });

    // filter_data
    builder.addCase(filter_data.pending, (state) => {
      state.loadingFilter_data = true;
    });
    builder.addCase(filter_data.fulfilled, (state, action) => {
      state.filter_data = action.payload;
      state.loadingFilter_data = false;
    });
    builder.addCase(filter_data.rejected, (state, action) => {
      state.errorFilter_data  = action.payload;
      state.loadingFilter_data = false;
    });

    // sort_data
    builder.addCase(sort_data.pending, (state) => {
      state.loadingSort_data = true;
    });
    builder.addCase(sort_data.fulfilled, (state, action) => {
      state.sort_data = action.payload;
      state.loadingSort_data = false;
    });
    builder.addCase(sort_data.rejected, (state, action) => {
      state.errorSort_data  = action.payload;
      state.loadingSort_data = false;
    });

    // filter_brducts
    builder.addCase(filter_brducts.pending, (state) => {
      state.loadingFilter_brducts = true;
    });
    builder.addCase(filter_brducts.fulfilled, (state, action) => {
      state.filter_brducts = action.payload;
      state.loadingFilter_brducts = false;
    });
    builder.addCase(filter_brducts.rejected, (state, action) => {
      state.errorFilter_brducts  = action.payload;
      state.loadingFilter_brducts = false;
    });

    // unfollow
    builder.addCase(unfollow.pending, (state) => {
      state.loadingUnfollow = true;
    });
    builder.addCase(unfollow.fulfilled, (state, action) => {
      state.unfollow = action.payload;
      state.loadingUnfollow = false;
    });
    builder.addCase(unfollow.rejected, (state, action) => {
      state.errorUnfollow  = action.payload;
      state.loadingUnfollow = false;
    });

    // unfollow
    builder.addCase(remove_wishlist.pending, (state) => {
      state.loadingRemove_wishlist = true;
    });
    builder.addCase(remove_wishlist.fulfilled, (state, action) => {
      state.remove_wishlist = action.payload;
      state.loadingRemove_wishlist = false;
    });
    builder.addCase(remove_wishlist.rejected, (state, action) => {
      state.errorRemove_wishlist  = action.payload;
      state.loadingRemove_wishlist = false;
    });

    // unfollow
    builder.addCase(return_orders.pending, (state) => {
      state.loadingReturn_orders = true;
    });
    builder.addCase(return_orders.fulfilled, (state, action) => {
      state.return_orders = action.payload;
      state.loadingReturn_orders = false;
    });
    builder.addCase(return_orders.rejected, (state, action) => {
      state.errorReturn_orders  = action.payload;
      state.loadingReturn_orders = false;
    });

    // exchange
    builder.addCase(exchange.pending, (state) => {
      state.loadingExchange = true;
    });
    builder.addCase(exchange.fulfilled, (state, action) => {
      state.exchange = action.payload;
      state.loadingExchange = false;
    });
    builder.addCase(exchange.rejected, (state, action) => {
      state.errorExchange  = action.payload;
      state.loadingExchange = false;
    });

    // category_data
    builder.addCase(category_data.pending, (state) => {
      state.loadingCategory_data = true;
    });
    builder.addCase(category_data.fulfilled, (state, action) => {
      state.category_data = action.payload;
      state.loadingCategory_data = false;
    });
    builder.addCase(category_data.rejected, (state, action) => {
      state.errorCategory_data  = action.payload;
      state.loadingCategory_data = false;
    });

    // category_data
    builder.addCase(ad_details.pending, (state) => {
      state.loadingAd_details = true;
    });
    builder.addCase(ad_details.fulfilled, (state, action) => {
      state.ad_details = action.payload;
      state.loadingAd_details = false;
    });
    builder.addCase(ad_details.rejected, (state, action) => {
      state.errorAd_details  = action.payload;
      state.loadingAd_details = false;
    });


  }
});

export const { addNumber } = API.actions;
export default API.reducer;







// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';

// // export const addUser = createAsyncThunk('user/add', async ({ userName, password }, thunkAPI) => {
// //   try {
// //     const response = await axios.post('https://localhost:7289/api/Account/Login', { userName, password });
// //     return response.data;
// //   } catch (error) {
// //     if (error.response) {
// //       // الخادم رد بمعلومات مفيدة
// //       console.error("Server responded with:", error.response.data);
// //       return thunkAPI.rejectWithValue(error.response.data);
// //     } else {
// //       // هناك مشكلة أخرى
// //       console.error("Error:", error.message);
// //       return thunkAPI.rejectWithValue(error.message);
// //     }
// //   }
// // });




// export const addUser = createAsyncThunk('user/add', async ({first_name, last_name,  password, email, role}) => {
//   const response = await axios.post('https://api.ashtiri.com/api/register', { first_name, last_name, password, email, role});
//   return response.data; 
// });

// // export const verification = createAsyncThunk('user/verification', async ({verification_code}) => {
// //   const response = await axios.post('https://api.ashtiri.com/api/verify-email', {verification_code});
// //   return response.data; 
// // });


// export const verification = createAsyncThunk('user/verification', async ({ verification_code }, thunkAPI) => {
//   try {
//     const response = await axios.post('https://api.ashtiri.com/api/verify-email', { verification_code });
//     return response.data;
//   } catch (error) {
//       if (error.response) {
//         console.error("Server responded with:", error.response.data);
//         return thunkAPI.rejectWithValue(error.response.data);
//       } else {
//         console.error("Error:", error.message);
//         return thunkAPI.rejectWithValue(error.message);
//       }
//   }
// });
  
// export const login = createAsyncThunk('user/login', async ({password, email}) => {
//   const response = await axios.post('https://api.ashtiri.com/api/login', {password, email});
//   return response.data; 
// });

// // export const login = createAsyncThunk('user/login', async ({password, email}) => {
// //     try {
// //       console.log({password, email }); // للتحقق من البيانات المرسلة
// //       console.log('passwordemail' ); // للتحقق من البيانات المرسلة
// //       const response = await axios.post('https://api.ashtiri.com/api/login', {password, email});
// //       return response.data;
// //     } catch (error) {
// //       if (error.response) {
// //         console.error("Server responded with:", error.response.data);
// //         // return thunkAPI.rejectWithValue(error.response.data);
// //       } else {
// //         console.error("Error:", error.message);
// //         // return thunkAPI.rejectWithValue(error.message);
// //       }
// //     }
// //   });
  





// // export const getCategories = createAsyncThunk('user/getCategories', async () => {
// //   const response = await axios.get('https://localhost:7289/api/Home/getCategories');
// //   return response.data; // نستخدم response.data للحصول على البيانات الفعلية
// // });


// export const API = createSlice({
//   name: 'Data',
//   initialState: {
//     number: 0,


//     userData: null,
//     login: null,
//     verificationData: null,



//     loadingVerification: false,





//     errorVerification:null,


//   },
//   reducers: {
//     addNumber: (state, action) => {
//       state.number = action.payload.number;
//     },
//   },
//   extraReducers: (builder) => {
//     // Handle addUser
//     builder.addCase(addUser.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(addUser.fulfilled, (state, action) => {
//       state.userData = action.payload;
//       state.loading = false;
//     });
//     builder.addCase(addUser.rejected, (state) => {
//       state.error = true;
//       state.loading = false;
//     });

//     // Handle login
//     builder.addCase(login.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(login.fulfilled, (state, action) => {
//       state.login = action.payload;
//       state.loading = false;
//     });
//     builder.addCase(login.rejected, (state) => {
//       state.error = true;
//       state.loading = false;
//     });

//     // Handle verification
//     builder.addCase(verification.pending, (state) => {
//       state.loadingVerification = true;
//     });
//     builder.addCase(verification.fulfilled, (state, action) => {
//       state.verificationData = action.payload;
//       state.loadingVerification = false;
//     });
//     builder.addCase(verification.rejected, (state, action) => {
//       state.errorVerification = action.payload;
//       state.loadingVerification = false;
//     });
//   }
// });

// export const { addNumber } = API.actions;
// export default API.reducer;