import './Main.css';
import img21 from "../images/istockphoto-953910510-612x612.jpg"
import icon9 from "../Icons/Icon-09.png"
import { category_data } from "../Redux/API";
import Loader from '../Loader';
import { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';


function Main() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoCategory = (aut) => {
    navigate('/SubSupCategory');
    dispatch(category_data({aut}));
  };

  const loadingAd_details = useSelector(state => state.Data.loadingAd_details );
  const ad_details = useSelector(state => state.Data.ad_details);


    const imagess = ad_details?.data?.data?.categories || [];
    const [startIndex, setStartIndex] = useState(0);
  
    const handleNext = () => {
      if (startIndex + 5 < imagess?.length) {
        setStartIndex(startIndex + 1);
      }
    };
  
    const handlePrev = () => {
      if (startIndex > 0) {
        setStartIndex(startIndex - 1);
      }
    };

    const getVisibleImages = () => {
      if (window.innerWidth >= 1400) { // شاشات XL
        return imagess?.slice(startIndex, startIndex + 3);
      } else if (window.innerWidth >= 1200) { // شاشات MD
        return imagess?.slice(startIndex, startIndex + 3);
      } else if (window.innerWidth >= 992) { // شاشات MD
        return imagess?.slice(startIndex, startIndex + 3);
      } else if (window.innerWidth >= 768) { // شاشات MD
        return imagess?.slice(startIndex, startIndex + 3);
      } else if (window.innerWidth >= 576) { // شاشات MD
        return imagess?.slice(startIndex, startIndex + 3);
      } else {
        return imagess?.slice(startIndex, startIndex + 3); // شاشات صغيرة
      }
    };


    const [startIndex1, setStartIndex1] = useState(0);
    const imagess1 = ad_details?.data?.data?.collections?.random_categories_with_parent     || [];
    const handleNext1 = () => {
      if (startIndex1 + 5 < imagess1?.length) {
        setStartIndex1(startIndex1 + 1);
      }
    };
  
    const handlePrev1 = () => {
      if (startIndex1 > 0) {
        setStartIndex1(startIndex1 - 1);
      }
    };

    const getVisibleImages1 = () => {
      if (window.innerWidth >= 1400) { // شاشات XL
        return imagess1?.slice(startIndex1, startIndex1 + 5);
      } else if (window.innerWidth >= 1200) { // شاشات MD
        return imagess1?.slice(startIndex1, startIndex1 + 5);
      } else if (window.innerWidth >= 992) { // شاشات MD
        return imagess1?.slice(startIndex1, startIndex1 + 4);
      } else if (window.innerWidth >= 768) { // شاشات MD
        return imagess1?.slice(startIndex1, startIndex1 + 3);
      } else if (window.innerWidth >= 576) { // شاشات MD
        return imagess1?.slice(startIndex1, startIndex1 + 3);
      } else {
        return imagess1?.slice(startIndex1, startIndex1 + 2); // شاشات صغيرة
      }
    };
  return (
    <div className="Main">
      <Header />
        <div className="img10 container g-0">
          {loadingAd_details && <Loader />}
          <img src={ad_details?.data?.data?.ad?.image_url}/>
        </div>

    
        <div className={`containerGalleryMain container g-0`}>
          <div className="galleryMain row justify-content-around align-items-center g-0">
          {loadingAd_details && <Loader />}
            <div className="col-1 text-center icon">
              <img src={icon9} className="iconLeft" onClick={handlePrev}/>
            </div>
                {getVisibleImages().map((item, index) => (
                    <div className='col-3 text-center align-items-center g-0'>
                      <div className='border3'>
                          <img key={index} src={item.image} alt={`img-${index}`} onClick={() => gotoCategory(item.name)} />
                      </div>
                      <p>{item.name}</p>
                    </div>
                ))}
            <div className="col-1 text-center icon">
            <img src={icon9} className="iconRitgh" onClick={handleNext}/>

            </div>
          </div>
        </div>
        
        <div className="gallery1 container g-0" >
        {loadingAd_details && <Loader />}
        <div className="col-1 text-center icon2 ">
          <img src={icon9} className="iconLeft" onClick={handlePrev1} />
        </div>
          <div className="mm row align-items-center justify-content-between g-0 border-0">
            {getVisibleImages1().map((item, index) => (
            <div className="box2 col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center g-0 border-0" key={index}>
                <img className="img_box2 g-0 " key={index} src={item.image} alt={`img-${index}`} onClick={() => gotoCategory(item.name)}/>
              </div>
            ))}
          </div>
          <div className="col-1 text-center icon2">
        <img src={icon9} className="iconRitgh" onClick={handleNext1}/>
        </div>
      </div>
{/* 
        <div className="img10 container g-0">
            <img src={img21}/>
        </div> */}
        <Footer/>
    </div>
  );
}

export default Main;