import { configureStore } from "@reduxjs/toolkit";
import AboutSlice from "./AboutSlice";
import HomeSlice from './HomeSlice'
import LoginSluce from "./LoginSluce";
import CalendarSlice from './CalendarSlice'
import rootReducer  from "./modeReducer";
import API from "./API";

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = configureStore({
    reducer: {
        about: AboutSlice,
        products: HomeSlice,
        // Login: LoginSluce,
        calander: CalendarSlice,
        Data: API,
        rootReducer,
    }
})

export default store;


// import { configureStore } from "@reduxjs/toolkit";
// import UserSlice from "./AboutSlice";
// const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

// const store = configureStore({
//     reducer: {
//         user: UserSlice
//     }
// })

// export default store;