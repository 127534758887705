import './SubSupCategory.css';
import img9 from "../images/IMG-20240723-WA0008.jpg"
import img8 from '../images/IMG-20240723-WA0008.jpg'
import img from "../images/IMG-20240723-WA0006.jpg"
import img2 from "../images/IMG-20240723-WA0008.jpg"
import img3 from "../images/IMG-20240723-WA0013.jpg"
import img4 from "../images/IMG-20240723-WA0018.jpg"
import img5 from "../images/IMG-20240723-WA0021.jpg"
import img6 from "../images/IMG-20240723-WA0022.jpg"
import img7 from "../images/IMG-20240723-WA0023.jpg"
import img10 from "../images/IMG-20240723-WA0008.jpg"
import img11 from "../images/IMG-20240723-WA0010.jpg"
import icon9 from "../Icons/Icon-09.png";

import {faCheck, faChevronLeft, faChevronRight, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { searsh_brand, show_brand, product_reviews, addFavorite, sort_products, category_data, filter_data, sort_data, filter_brducts, } from "../Redux/API";
import { productDetails } from "../Redux/HomeSlice";
import Loader from "../Loader";

import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'; // استيراد التنسيقات الافتراضية

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
function SubSupCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const gotoCategory = (aut) => {
    navigate('/SubSupCategory');
    dispatch(category_data({aut}));
  };
  // useEffect(() => {
  //   const savedData = localStorage.getItem('categoryData');
  //   if (savedData) {
  //     const parsedData = JSON.parse(savedData);
  //     dispatch(category_data({ aut: parsedData }));
  //   }
  // }, [dispatch]);

  const category_datas = useSelector(state => state.Data.category_data);
  const loadingCategory_data = useSelector(state => state.Data.loadingCategory_data);


  // const imagess = category_datas?.data?.data?.categories;
      

const imagess = category_datas?.data?.data?.categories || [];
const [startIndex, setStartIndex] = useState(0);

const handleNext = () => {
  if (startIndex + 5 < imagess?.length) {
    setStartIndex(startIndex + 1);
  }
};

const handlePrev = () => {
  if (startIndex > 0) {
    setStartIndex(startIndex - 1);
  }
};

const getVisibleImages = () => {
  if (window.innerWidth >= 1400) { // شاشات XL
    return imagess?.slice(startIndex, startIndex + 3);
  } else if (window.innerWidth >= 1200) { // شاشات MD
    return imagess?.slice(startIndex, startIndex + 3);
  } else if (window.innerWidth >= 992) { // شاشات MD
    return imagess?.slice(startIndex, startIndex + 3);
  } else if (window.innerWidth >= 768) { // شاشات MD
    return imagess?.slice(startIndex, startIndex + 3);
  } else if (window.innerWidth >= 576) { // شاشات MD
    return imagess?.slice(startIndex, startIndex + 3);
  } else {
    return imagess?.slice(startIndex, startIndex + 3); // شاشات صغيرة
  }
};
    const [searshBroduct, setSearshBroduct] = useState('');
  
    // جلب بيانات البحث أو العلامة التجارية عند تغيير قيمة البحث
    useEffect(() => {
      if (searshBroduct) {
        dispatch(searsh_brand({ searshBroduct })); // البحث عن المنتجات
      } else {
        dispatch(show_brand()); // عرض العلامات التجارية الأصلية
      }
    }, [searshBroduct, dispatch]);
  
    
  
    const gotoProductDetails = (product) => {
      if (!product || !product.id) {
        console.error('Product data is missing or invalid');
        return;
      }
      dispatch(productDetails(product));
      dispatch(product_reviews(product.id));
  
      // التنقل إلى صفحة التفاصيل
      navigate('/Product');
    };
    const loadingSearsh_brand = useSelector(state => state.Data.loadingSearsh_brand);
    const searsh_brands = useSelector(state => state.Data.searsh_brand);
  
    const [icon, setIcon] = useState(false);
    const [text, setText] = useState();
    function handelClick() {
        setIcon(!icon);
    }
    useEffect(() => {
      dispatch(sort_products(text)); 
      dispatch(filter_data()); 
      dispatch(sort_data()); 
    }, [dispatch, text]);
  
    // useEffect(() => {
    //   if (!loadingUnfollow && !loadingRemove_wishlist) {
    //       dispatch(myProfile(orderNumber));
    //   }
    //   }, [dispatch, loadingUnfollow, loadingRemove_wishlist, orderNumber]);
  
  const sort_product = useSelector(state => state.Data.sort_products);
  const loadingSort_products = useSelector(state => state.Data.loadingSort_products);
  
  
  const filters_data = useSelector(state => state.Data.filter_data);
  const sorts_data = useSelector(state => state.Data.sort_data);
  const loadingFilter_brducts = useSelector(state => state.Data.loadingFilter_brducts);
  
  
  console.log(sort_product)
  console.log(searsh_brands)
  console.log(category_datas)
  console.log(imagess)
  console.log(text)

  console.log('777777777777777777777777777777777777777777777777777777777777777777777')
  
  
  
  
    const [likedIds, setLikedIds] = useState([]);
  
    const toggleLike = (product) => {
      const isLiked = likedIds.includes(product.id);
      const updatedFavorite = !product.favorite; // تغيير قيمة favorite بعد النقر
    
      // تحديث likedIds وحالة المفضلة
      if (isLiked) {
        setLikedIds((prev) => prev.filter((id) => id !== product.id));
      } else {
        setLikedIds((prev) => [...prev, product.id]);
      }
      // إرسال حالة المفضلة الجديدة إلى الـ backend
      dispatch(addFavorite({ product_id: product.id}));
    };
  
  
  
  
  

    const [openIndex, setOpenIndex] = useState(null);
      const [selectedFilters, setSelectedFilters] = useState({
        brands: [],
        categories: [],
        prices: [],
        discounts: [],
        colors: [],
        sizes: [],
      });

      const handleFilterDataClick = () => {
        dispatch(filter_brducts({selectedFilters})); 
      };
        const brands = {
          title: t('brands'),
          subItems: filters_data?.data?.data?.brands,
        };
      
        const items = [
          {
            title: t('categories'),
            key: 'categories', 
            subItems: filters_data?.data?.data?.categories,
          },
          {
            title: t('prices'),
            key: 'prices', 
            subItems: filters_data?.data?.data?.prices,
          },
          {
            title: t('discounts'),
            key: 'discounts', 
            subItems: filters_data?.data?.data?.discounts,
          },
          {
            title: t('colors'),
            key: 'colors', 
            subItems: filters_data?.data?.data?.colors,
          },
          {
            title: t('sizes'),
            key: 'sizes', 
            subItems: filters_data?.data?.data?.sizes,
          },
        ];

        const handleAddFilter = (type, filter) => {
          setSelectedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
        
            // تأكد من أن `updatedFilters[type]` هو مصفوفة قبل محاولة إضافة الفلتر
            if (!Array.isArray(updatedFilters[type])) {
              updatedFilters[type] = [];
            }
        
            // لو الفلتر هو "brands"، مرر `id` فقط
            if (type === 'brands') {
              if (updatedFilters[type].includes(filter.id)) {
                // لو الفلتر موجود، امسحه
                updatedFilters[type] = updatedFilters[type].filter(id => id !== filter.id);
              } else {
                // لو مش موجود، ضيفه
                updatedFilters[type] = [...updatedFilters[type], filter.id];
              }
            } 
            // للفئات الأخرى، مرر `title` فقط
            else {
              if (updatedFilters[type].includes(filter.title)) {
                // لو الفلتر موجود، امسحه
                updatedFilters[type] = updatedFilters[type].filter(title => title !== filter.title);
              } else {
                // لو مش موجود، ضيفه
                updatedFilters[type] = [...updatedFilters[type], filter.title];
              }
            }
        
            return updatedFilters;
          });
        };
    
      const handleClick = (index) => {
          setOpenIndex(openIndex === index ? null : index);
      };
  
      const icons3 = [['','','',''], ['','',''], ['','']]
      const [openInde, setOpenInde] = useState(2);
      const [selectedIndex, setSelectedIndex] = useState(2);
  
      const handleRs = (index) => {
        setOpenInde(index)
      };
  
  
      const [circles, setCircles] = useState([1, 2, 3]);
      const [backendNumber, setBackendNumber] = useState(50); // أو الرقم اللي هتاخده من الباك اند
      const [activeIndex, setActiveIndex] = useState(0); // لحفظ الدائرة النشطة
    
      // فانكشن لتحديث الأرقام عند الضغط على السهم اليمين
      const handleRightArrow = () => {
        if (activeIndex < circles.length - 1) {
          setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
        } else {
          setCircles((prevCircles) => {
            if (prevCircles.every(num => num < backendNumber)) {
              return prevCircles.map(num => num + 1);
            }
            return prevCircles;
          });
        }
      };
  
      // فانكشن لتحديث الأرقام عند الضغط على السهم الشمال
      const handleLeftArrow = () => {
        if (activeIndex > 0) {
          setActiveIndex((prevActiveIndex) => prevActiveIndex - 1);
        } else {
          setCircles((prevCircles) => {
            if (prevCircles.every(num => num > 1)) {
              return prevCircles.map(num => num - 1);
            }
            return prevCircles;
          });
        }
      };
  
  return (
    
    <div className="SubSupCategory g-0">
      <Header />
      <div className="img10 container g-0">
      {loadingCategory_data && <Loader />}
        <img src={category_datas?.data?.data?.banner_ad?.image_url}/>
      </div>
      {category_datas?.data?.data?.categories.length === 0 &&
      <p className=' text-center font-size2'>{category_datas?.data?.data?.banner_ad?.title}</p>
      }
          <div className={`containerGalleryMain container g-0`}>
          {category_datas?.data?.data?.categories?.length !== 0 && category_datas?.data?.data?.categories && (
            <div className="galleryMain row justify-content-around align-items-center g-0">
              <div className="col-1 text-center icon">
                <img src={icon9} className="iconLeft" onClick={handlePrev} />
              </div>

              {getVisibleImages().map((item, index) => (
                <div className='col-3 text-center align-items-center g-0' key={index}>
                  <div className='border3'>
                    <img src={item.image} alt={`img-${index}`} onClick={() => gotoCategory(item.name)} />
                  </div>
                  <p>{item.name}</p>
                </div>
              ))}

              <div className="col-1 text-center icon">
                <img src={icon9} className="iconRitgh" onClick={handleNext} />
              </div>
            </div>
          )}
        </div>
        <span className='hr'></span>
       
          <div className='containe container'>
          <div className='border_box2'>
            <div className='background'>
            <p className='Produ'>{filters_data?.data?.data?.total_products} {t('Products')}</p>
            <div>
                {icons3.map((e, i) => (
                  <div key={i}    className={
                    e.length === 2 
                      ? 'show-on-small1' 
                      : e.length === 3 
                      ? 'show-on-small2' 
                      : e.length === 4 
                      ? 'show-on-small3' 
                      : ''
                  }>
                    <p
                      className={`p ${openInde === i ? 'selected' : ''}`}
                      onClick={() => handleRs(i)}
                    >
                      {e.map((item, index) => (
                        <span key={index} className={openInde === i ? 'selected' : ''}>
                          {item}
                        </span>
                      ))}
                    </p>
                    {openInde === i && (
                      <span
                        className="arrow"
                        style={{
                          marginInlineStart:
                            document.documentElement.dir === 'rtl'
                              ? '-7px'
                              : openInde === 0 || openInde === 1 || openInde === 2
                              ? '-25px'
                              : '',
                        }}
                      >
                        &#9650;
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <div className="boxList">
                <div className="level">
                <p>{t('Sort by')}: <span>{text}</span></p>
                <i onClick={handelClick} className={`${icon ? 'activ' : 'noActiv'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                </div>

                {icon &&
                  <div className="levels">
                    {sorts_data?.data?.data?.filter_titles.map((e, i) => 
                      <p key={i} onClick={() => (setText(e), setIcon(!icon))}>{e}</p>
                    )}
                  </div>
                }
              </div>
          </div>
          </div>
        </div>



        <div className="main-container container">
            <div className="main-cont col-4" style={{ display: openInde === 0 || openInde === 1 ? 'none' : 'block' }}>
            <div className="Filter_icon">
          <p>Filter</p>
          <i className="i" onClick={handleFilterDataClick}>
            <FontAwesomeIcon icon={faCheck} />
          </i>
        </div>

    
        {/* عرض البراندات */}
        <div className="main-item">
          <div className="item-headerr" onClick={() => setOpenIndex(openIndex === 0 ? null : 0)}>
            <p>{brands.title}</p>
            <p className="arrow">
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </p>
          </div>
          {openIndex === 0 && (
            <div className="sub-items">
               {brands?.subItems?.map((brand, brandIndex) => (
                <div
                  key={brandIndex}
                  className="sub-item d-flex justify-content-between hover-bg"
                  onClick={() => handleAddFilter('brands', { id: brand.id })}
                >
                  <p>{brand.filter_title}</p>
                    <div className='m d-flex '>
                      <p className='mx-2'>{brand.quantity}</p>
                      {selectedFilters.brands?.includes(brand.id) && <span>✅</span>}
                    </div>
                </div>
              ))}

            </div>
          )}
        </div>

        {items?.map((item, index) => (
          <div key={index} className="main-item">
            <div className="item-headerr" onClick={() => setOpenIndex(openIndex === index + 1 ? null : index + 1)}>
              <p>{item.title}</p>
              <p className="arrow">
                <i>
                  <FontAwesomeIcon icon={faChevronRight} />
                </i>
              </p>
            </div>
            {openIndex === index + 1 && (
              <div className="sub-items">
                  {item?.subItems?.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      className="sub-item d-flex justify-content-between hover-bg"
                      onClick={() => handleAddFilter(item.key, { title: subItem.filter_title })}
                    >
                      <p>{subItem.filter_title}</p>
                      <div className='m d-flex '>
                      <p className='mx-2'>{subItem.quantity}</p>
                      {selectedFilters[item.key]?.includes(subItem.filter_title) && <span>✅</span>}
                      </div>
                    </div>
                  ))}

              </div>
            )}
          </div>
        ))}
      </div>
    {/* </div> */}


            <div className="gallery1 container">
            <div className="row align-items-center justify-content-around g-0">
              {loadingSearsh_brand && <Loader />}
              {loadingSort_products && <Loader />}
              {loadingFilter_brducts && <Loader />}
              {loadingCategory_data && <Loader />}
                {/* عرض المنتجات بناءً على وجود الفلتر أو الترتيب أو البحث */}
                {category_datas ? (
                  // عرض نتائج الفلتر
                  category_datas?.data?.data?.products.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>        <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : sort_product ? (
                  // عرض نتائج الترتيب
                  sort_product?.data?.data?.products.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>
                              <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  // عرض جميع المنتجات الخاصة بالعلامة التجارية
                  category_datas?.data?.data?.products?.map((product, index) => (
                    <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                    col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
                  col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
                  col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
                  col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                  col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
                    text-center g-0 mb-3`}>        <div className="border_box">
                        <div className="padding_box">
                          <i
                            className={`font-size2 ${likedIds.includes(product.id) ? "liked" : ""}`}
                            onClick={() => toggleLike(product)}
                            style={{ color: likedIds.includes(product.id) || product.favorite ? 'red' : '#777' }}
                          >
                            <FontAwesomeIcon icon={faHeart} />
                          </i>
                          {product?.discount && <p className="discount">Off {product?.discount}</p>}
                          {product.main_image && (
                            <img
                              onClick={() => gotoProductDetails(product)}
                              src={product.main_image}
                              alt={`img-${index}`}
                            />
                          )}
                          <p className="discraptoin">{product?.description}</p>
                          <div className="price align-items-center">
                            <p>{product?.new_price}</p>
                            {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
       
      </div>
     
        </div>
        {/* <div className="circles container">
            <p className='pr'><i onClick={handleLeftArrow}><FontAwesomeIcon icon={faChevronLeft} /></i>  previous Page</p>
                {circles.map((num, index) => (
                <div key={index} className={`circle ${index === activeIndex ? 'active' : ''}`}>
                    {num}
                </div>
                ))}
                <span>...</span>
                <div className='circle'>{backendNumber}</div>
            <p className='pr'>Next Page  <i onClick={handleRightArrow}><FontAwesomeIcon icon={faChevronRight} /></i> </p>
            </div> */}
        <Footer />
    </div>
  );
}

export default SubSupCategory;