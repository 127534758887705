import './ForgotPassword.css';
import group from "../Icons/Group.png";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendReset, resetPassword, verifyReset } from '../Redux/API';
import Loader from '../Loader';

function ForgotPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const sendResetData = useSelector(state => state.Data.sendReset);
    const loadingSendReset = useSelector(state => state.Data.loadingSendReset);
    const errorSendReset = useSelector(state => state.Data.errorSendReset);
    
    const verifyResetData = useSelector(state => state.Data.verifyReset);
    const loadingVerifyReset = useSelector(state => state.Data.loadingVerifyReset);
    const errorVerifyReset = useSelector(state => state.Data.errorVerifyReset);

    const resetPasswordData = useSelector(state => state.Data.resetPassword);
    const loadingresetPassword = useSelector(state => state.Data.loadingresetPassword);
    const errorresetPassword = useSelector(state => state.Data.errorresetPassword);

    

    // useEffect(() => {
    //     if (sendResetData) {
    //         window.localStorage.setItem('sendResetData', JSON.stringify(sendResetData));
    //     }
    // }, [sendResetData]);

    // const [getLocalStorage, setGetLocalStorage] = useState(window.localStorage.getItem('sendResetData'));
    // const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
    // const [showToken, setShowToken] = useState(valueLocalStorage ? valueLocalStorage?.data?.token : null);

    const isValidEmailReset = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [emailValid, setEmailValid] = useState('');
    const [errorResetPassword, setErrorResetPassword] = useState('');
    const [loginErrorReset, setLoginErrorReset] = useState('');
    const [otpReset, setOtpReser] = useState('');
    const [errorReset, setErrorReset] = useState('');
    const [isFormValidEmail, setIsFormValidEmail] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);

    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtpContainer, setShowOtpContainer] = useState(false);
    const [showPasswordContainer, setShowPasswordContainer] = useState(false);
    const [showEmail, setShowEmail] = useState(true);

    useEffect(() => {
        const isValid = isValidEmailReset(emailValid);
        setIsFormValidEmail(isValid);
    }, [emailValid]);

    const handleSubmitEmail = () => {
        if (!isFormValidEmail) {
            setLoginErrorReset('Please fill in all fields with valid information.');
        } else {
            // حفظ البريد الإلكتروني في localStorage
            window.localStorage.setItem('userEmail', emailValid);

            dispatch(sendReset({email: emailValid }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowOtpContainer(true);
                    setShowEmail(false)
                }
            })

        }
    };




    const isValidPassword = (password) => {
        // تحقق من أن الـ Password يحتوي على حروف كبيرة وصغيرة وأرقام
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmitPassword = () => {
        if (!Password || !confirmPassword) {
            setErrorResetPassword('يرجى إدخال كلمة المرور وتأكيدها.');
        } else if (!isValidPassword(Password)) {
            setErrorResetPassword('يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام.');
        } else if (Password !== confirmPassword) {
            setErrorResetPassword('كلمات المرور غير متطابقة.');
        } else {
            setErrorResetPassword('');
            setPasswordValid(true)
            const storedEmail = window.localStorage.getItem('userEmail');
            dispatch(resetPassword({ email: storedEmail, password: Password, password_confirmation:confirmPassword}));
        }
    };

    useEffect(() => {
        if (Password && confirmPassword) {
            setPasswordValid(isValidPassword(Password) && Password === confirmPassword);
        } else {
            setPasswordValid(false);
        }
    }, [Password, confirmPassword]);

    

    const handleOTPSubmitReset = () => {
        const formattedOtp = otpReset.replace(/\s/g, '');
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
            setErrorReset('');
            // استخدام البريد الإلكتروني من localStorage عند إرسال OTP
            const storedEmail = window.localStorage.getItem('userEmail');
            dispatch(verifyReset({ email: storedEmail, verification_code: formattedOtp }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowPasswordContainer(true);
                    setShowOtpContainer(false)
                    setShowEmail(false)
                }
            });
        } else {
            setErrorReset('OTP must be exactly 5 digits and must be a number.');
        }
    };

    const [isOtpValid, setIsOtpValid] = useState(false);
    useEffect(() => {
        // تحقق من أن الـ OTP يتكون من 5 أرقام
        const formattedOtp = otpReset.replace(/\s/g, '');
        setIsOtpValid(formattedOtp.length === 5 && !isNaN(formattedOtp));
    }, [otpReset]);

    const handleOtpChangeReset = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtpReser(value.split('').join(' '));
        }
    };

    const handleIconClick = () => {        
        window.localStorage.removeItem('Register');
    //     setFirstName('');
    //     setLastName('');
    //     setLoginEmail('');
    //     setLoginPassword('');
    //     setOtp('');
    //     setActiveButton(null);
    //     // setShowOTP(false);
    //     setShowToken(false);
    };
    
    return (
        <div className="ForgotPassword">
            {showEmail &&
                <div className='reset container'>
                    <div className='row'>
                        <div className='cont col-4 m-auto g-0'>
                            {loadingSendReset && <Loader />}
                            <div className='icon'>
                                <i onClick={handleIconClick}>icon</i>
                            </div>
                            <div className='logo'>
                                <img src={group} alt="group"/>
                                <p>Welcome to the new Experience</p>
                            </div>
                            <div className='div3'>
                                <div>
                                    <input
                                        placeholder='Please enter email'
                                        onChange={(e) => setEmailValid(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='error-message'>{loginErrorReset}</div>
                            <div className='error-message'>{errorSendReset?.message?.message}</div>
                            <div className='div6'>
                                <div>
                                    <p
                                        className={isFormValidEmail ? 'continue-button valid' : 'continue-button'}
                                        onClick={handleSubmitEmail}
                                    >
                                        CONTINUE
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {showOtpContainer&& (
            <div className='otp container'>
                <div className='row'>
                    <div className='cont col-4 m-auto g-0'>
                    {loadingVerifyReset && <Loader />}
                        <div className='icon'>
                            <i onClick={handleIconClick}>icon</i>
                        </div>
                        <div className='logo'>
                            <img src={group} alt="group" />
                            <p>Welcome to the new Experience</p>
                        </div>
                        <div className='div1'>
                            <p>VERIFY YOUR EMAIL ADDRESS</p>
                        </div>
                        <div className='div13'>
                            <p className='font-size1'>Verify your email using the OTP sent to your email address</p>
                        </div>
                        <div className='div14'>
                            <p>Resend OTP</p>
                        </div>
                        <div className='div15'>
                            <p>OTP</p>
                            <input
                                className="otp-input"
                                value={otpReset}
                                onChange={handleOtpChangeReset}
                                placeholder="0 0 0 0 0"
                                maxLength="9"
                            />
                        </div>

                        {otpReset && <div className='error-message'>{errorReset}</div>}
                        <div className='error-message'>{errorVerifyReset?.message}</div>

                        <div className='div6'>
                            <div>
                                <p
                                    className={`continue-button ${isOtpValid ? 'valid' : ''}`}
                                    onClick={handleOTPSubmitReset}
                                >
                                    CONTINUE
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

            {showPasswordContainer && (
            <div className='reset container'>
                <div className='row'>
                    <div className='cont col-4 m-auto g-0'>
                        {loadingresetPassword && <Loader />}
                        <div className='icon'>
                            <i onClick={() => { /* أي أكشن هنا */ }}>icon</i>
                        </div>
                        <div className='logo'>
                            <img src={group} alt="group"/>
                            <p>Welcome to the new Experience</p>
                        </div>
                        <div className='div1'>
                            <p>Create your account</p>
                        </div>

                        <div className='div4'>
                            <div>
                                <input
                                    placeholder='Password'
                                    type="password"
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='div4'>
                            <div>
                                <input
                                    placeholder='Confirm Password'
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='error-message'>{errorResetPassword}</div>
                        <div className='error-message'>{errorresetPassword?.message}</div>
                        <div className='div6'>
                            <div>
                                <p
                                className={`continue-button ${isPasswordValid ? 'valid' : ''}`}
                                    // className={isPasswordValid ? 'continue-button valid' : 'continue-button'}
                                    onClick={handleSubmitPassword}
                                >
                                    CONTINUE
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}

export default ForgotPassword;
// import './ForgotPassword.css';
// import group from "../Icons/Group.png";
// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { sendReset, login, verification, verifyReset } from '../Redux/API';
// // import Loader from '../Loader';
// import Loader from '../Loader';

// function ForgotPassword() {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const errorRegister = useSelector(state => state.Data.errorAddUser);
//     const messageOTP = useSelector(state => state.Data.errorVerification);
//     const errorLogin =useSelector(state => state.Data.errorLogin)


//     const sendResetData = useSelector(state => state.Data.sendReset);
//     const loadingSendReset = useSelector(state => state.Data.loadingSendReset);
//     const errorSendReset = useSelector(state => state.Data.errorSendReset);
    
//     const verifyResetData = useSelector(state => state.Data.verifyReset);
//     const loadingVerifyReset = useSelector(state => state.Data.loadingVerifyReset);
//     const errorVerifyReset = useSelector(state => state.Data.errorVerifyReset);


//     const loadingAddUser = useSelector(state => state.Data.loadingAddUser);
//     const loadingVerification = useSelector(state => state.Data.loadingVerification);
//     const loadingLogin = useSelector(state => state.Data.loadingLogin);





//     useEffect(() => {
//         if (sendResetData) {
//             window.localStorage.setItem('sendResetData', JSON.stringify(sendResetData));
//         }
//     }, [sendResetData]);

//     const [getLocalStorage, setGetLocalStorage] = useState(window.localStorage.getItem('sendResetData'));
//     const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
//     // const [showToken, setShowToken] = useState(null);
//     const [showToken, setShowToken] = useState(valueLocalStorage ? valueLocalStorage?.data?.token : null);


//     console.log(valueLocalStorage)

//     useEffect(() => {
//         if (sendResetData) {
//             setShowToken(true);
//         }
//     }, [sendResetData]);


//     const isValidEmail = (email) => {
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         const isValid = emailRegex.test(email);
//         return isValid;
//     };

//     const [activeButton, setActiveButton] = useState('signup');

//     const [emailValid, setEmailValid] = useState('');
    
//     const [email, setEmail] = useState(emailValid);
//     console.log(email)
//     console.log(email)
//     // const [signupEmail, setSignupEmail] = useState('');

    
//     // const [signupPassword, setSignupPassword] = useState('');
//     const [signupError, setSignupError] = useState('');
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [role, setRole] = useState('customer');

//     const [loginEmail, setLoginEmail] = useState('');
//     const [loginPassword, setLoginPassword] = useState('');
//     const [loginError, setLoginError] = useState('');

//     const [otp, setOtp] = useState('');
//     const [error, setError] = useState('');
//     const [isFormValidEmail, setIsFormValidEmail] = useState(false);
//     const [isFormValid, setIsFormValid] = useState(false);
//     const [isFormValidPassword, setIsFormValidPassword] = useState(false);


//     const [resetPassword, setResetPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');


//     const [showOTP, setShowOTP] = useState(false);

//     useEffect(() => {
//             const isValid = isValidEmail(emailValid);
//             setIsFormValidEmail(isValid);
//     }, [emailValid]);


//     const handleSubmitEmail = () => {
//         if (!isFormValidEmail) {
//             setLoginError('Please fill in all fields with valid information.');
//         } else {
//             dispatch(sendReset({
//                 email: emailValid,  
//             }));
//         }
//     };

    
//     const isValidPassword = (password) => {
//         // تحقق من أن الـ Password يحتوي على حروف كبيرة وصغيرة وأرقام
//         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
//         return passwordRegex.test(password);
//     };

//     const handleSubmitPassword = () => {
//         if (!resetPassword || !confirmPassword) {
//             setSignupError('يرجى إدخال كلمة المرور وتأكيدها.');
//         } else if (!isValidPassword(resetPassword)) {
//             setSignupError('يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام.');
//         } else if (resetPassword !== confirmPassword) {
//             setSignupError('كلمات المرور غير متطابقة.');
//         } else {
//             setSignupError('');
//             dispatch(login({
//                 email: loginEmail,
//                 password: resetPassword
//             }));
//         }
//     };
    

//     const handleOTPSubmit = () => {
//         const formattedOtp = otp.replace(/\s/g, '');
//         console.log(formattedOtp)
//         console.log('Formatted OTP:', formattedOtp);
//         if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
//             setError('');
//             dispatch(verifyReset({email: email, verification_code: formattedOtp }));
//         } else {
//             console.log('Invalid OTP:', formattedOtp);
//             setError('OTP must be exactly 5 digits and must be a number.');
//         }
//     };


//     const handleOtpChange = (e) => {
//         const value = e.target.value.replace(/\s/g, '');
//         if (/^\d*$/.test(value) && value.length <= 5) {
//             setOtp(value.split('').join(' '));
//             console.log('OTP input updated:', value);
//         }
//     };

//     const handleIconClick = () => {        
//         // إزالة البيانات من localStorage
//         window.localStorage.removeItem('Register');
        
//         // إعادة تعيين القيم للحالة
//         // setSignupEmail('');
//         // setSignupPassword('');
//         setFirstName('');
//         setLastName('');
//         setLoginEmail('');
//         setLoginPassword(''); // تأكد من أنك تعيد تعيين قيمة `loginPassword`
//         setOtp('');
        
//         // إعادة تعيين الأزرار والحالة
//         setActiveButton(null);
//         setShowOTP(false);
//         setShowToken(false);
//     };
    

//     return (
//         <div className="ForgotPassword">
//             <div className='container'>
//                 {!showToken &&
//                 <>
//                 {activeButton === 'signup' &&
//                     <div className='row'>
//                         <div className='cont col-4 m-auto g-0'>
//                             {loadingSendReset && <Loader />}
//                             <div className='icon'>
//                                 <i onClick={handleIconClick}>icon</i>
//                             </div>
//                             <div className='logo'>
//                                 <img src={group} alt="group"/>
//                                 <p>Welcome to the new Experience</p>
//                             </div>
//                             <div className='div3'>
//                                 <div>
//                                     <input
//                                         placeholder='Please enter email'
//                                         // value={signupEmail}
//                                         onChange={(e) => setEmailValid(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
                            
//                             <div className='error-message'>{loginError}</div>
//                             <div className='error-message'>{errorSendReset?.message?.message}</div>
//                             <div className='div6'>
//                                 <div>
//                                     <p
//                                         className={isFormValidEmail ? 'continue-button valid' : 'continue-button'}
//                                         onClick={handleSubmitEmail}
//                                     >
//                                         CONTINUE
//                                     </p>
//                                 </div>
//                             </div>
                            
//                         </div>
//                     </div>
//                 }
//                 </>
//                 }
//             </div>

//             <div className='container'>
//                 {/* {showToken && */}
//                     <div className='row'>
//                         <div className='cont col-4 m-auto g-0'>
//                         {loadingVerification && <Loader />}
//                             <div className='icon'>
//                                 <i onClick={handleIconClick}>icon</i>
//                             </div>
//                             <div className='logo'>
//                                 <img src={group} alt="group" />
//                                 <p>Welcome to the new Experience</p>
//                             </div>
//                             <div className='div1'>
//                                 <p>VERIFY YOUR EMAIL ADDRESS</p>
//                             </div>
//                             <div className='div13'>
//                                 <p className='font-size1'>Verify your email using the OTP sent to your email address</p>
//                             </div>
//                             <div className='div14'>
//                                 <p>Resend OTP</p>
//                             </div>

//                             {error && <div className='error-message'>{error}</div>}
//                             <div className='error-message'>{messageOTP?.message}</div>
//                             <div className='div15'>
//                                 <p>OTP</p>
//                                 <input
//                                     className="otp-input"
//                                     value={otp}
//                                     onChange={handleOtpChange}
//                                     placeholder="0 0 0 0 0"
//                                     maxLength="9" // Maximum length including spaces
//                                 />
//                             </div>
                            
//                             <div className='div6'>
//                                 <div>
//                                     <p
//                                         className='continue-button valid'
//                                         onClick={handleOTPSubmit}
//                                     >
//                                         CONTINUE
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 {/* } */}
//             </div>

//             <div className='reset container'>
//                 <div className='row'>
//                     <div className='cont col-4 m-auto g-0'>
//                         {loadingLogin && <Loader />}
//                         <div className='icon'>
//                             <i onClick={() => { /* أي أكشن هنا */ }}>icon</i>
//                         </div>
//                         <div className='logo'>
//                             <img src={group} alt="group"/>
//                             <p>Welcome to the new Experience</p>
//                         </div>
//                         <div className='div1'>
//                             <p>Create your account</p>
//                         </div>

//                         <div className='div4'>
//                             <div>
//                                 <input
//                                     placeholder='Password'
//                                     type="password"
//                                     value={resetPassword}
//                                     onChange={(e) => setResetPassword(e.target.value)}
//                                 />
//                             </div>
//                         </div>

//                         <div className='div4'>
//                             <div>
//                                 <input
//                                     placeholder='Confirm Password'
//                                     type="password"
//                                     value={confirmPassword}
//                                     onChange={(e) => setConfirmPassword(e.target.value)}
//                                 />
//                             </div>
//                         </div>

//                         <div className='error-message'>{signupError}</div>

//                         <div className='div6'>
//                             <div>
//                                 <p
//                                     className={isFormValid ? 'continue-button valid' : 'continue-button'}
//                                     onClick={handleSubmitPassword}
//                                 >
//                                     CONTINUE
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ForgotPassword;