import React, { useState, useCallback } from 'react';
import { faHeart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import img9 from '../images/IMG-20240723-WA0008.jpg';
import img8 from '../images/IMG-20240723-WA0008.jpg';
import img from '../images/IMG-20240723-WA0006.jpg';
import img2 from '../images/IMG-20240723-WA0008.jpg';
import img3 from '../images/IMG-20240723-WA0013.jpg';
import img4 from '../images/IMG-20240723-WA0018.jpg';
import img5 from '../images/IMG-20240723-WA0021.jpg';
import img6 from '../images/IMG-20240723-WA0022.jpg';
import img7 from '../images/IMG-20240723-WA0023.jpg';
import img10 from '../images/IMG-20240723-WA0008.jpg';
import img11 from '../images/IMG-20240723-WA0010.jpg';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Favorites.css'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { addFavorite, product_reviews } from "../Redux/API";
import { productDetails } from "../Redux/HomeSlice";
import Loader from '../Loader';
import { summary, get_user_return, get_user_exchange, myProfile, unfollow, remove_wishlist, return_orders, exchange} from "../Redux/API";


function Favorites() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const gotoProductDetails = (product) => {
      dispatch(productDetails(product));  
      dispatch(product_reviews(product.id)); 
      console.log(product.id)
      navigate('/Product');  
    };

    // const [likedIds, setLikedIds] = useState([]);

    // const toggleLike = (product) => {
    //   const isLiked = likedIds.includes(product.id);
    //   const updatedFavorite = !product.favorite; // تغيير قيمة favorite بعد النقر
    
    //   // تحديث likedIds وحالة المفضلة
    //   if (isLiked) {
    //     setLikedIds((prev) => prev.filter((id) => id !== product.id));
    //   } else {
    //     setLikedIds((prev) => [...prev, product.id]);
    //   }
    //   // إرسال حالة المفضلة الجديدة إلى الـ backend
    //   dispatch(addFavorite({ product_id: product.id}));
    // };

    const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
    useEffect(() => {
        if (!loadingRemove_wishlist) {
            dispatch(myProfile());
        }
        }, [dispatch, loadingRemove_wishlist]);

    const myProfiles = useSelector(state => state?.Data.myProfile);
    const handelremove_wishlist = (id) => {
        dispatch(remove_wishlist({ id }));
    };
    return (
        
        <>
            <Header />
            <div className='container offers'>
                <div className='cont row justify-content-between g-0 p-0'>
                <h3 className='title'>{t('favorites')}</h3>
                <div className='wishlist col border-0'>
                        <div className="gallery1 container ">
                        {loadingRemove_wishlist && <Loader />}
                            <div className="row_prodect row  align-items-center justify-content-around  p-0 g-0 border-0">
                            {myProfiles?.data?.data?.user?.wishlist?.map((product, index) => (
                                    <div key={index} className="box col-5 col-sm-4 px-sm-2  col-md-4 px-md-3   col-lg-3 px-lg-3 mx-lg-0  col-xl-2 mx-xl-1   px-xl-0   text-center g-0 mb-3">
                                        <div className="border_box">
                                            <div className="padding_box">
                                                <i><FontAwesomeIcon icon={faTimes} onClick={() => handelremove_wishlist(product.id)} /></i>
                                                {product?.discount && <p className="discount">{t('Off')} {product?.discount}</p>}
                                                {product.main_image && <img onClick={() => gotoProductDetails(product)} src={product?.main_image} alt={`img-${index}`} />}
                                                <p className="discraptoin">{product?.description}</p>
                                                <div className="price align-items-center">
                                                    <p>{product?.new_price}</p>
                                                    {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* <div className="row_prodect2 row align-items-center justify-content-around g-0">
                                {pepoles.map((image, index) => (
                                    <div key={index} className="box box_cont col-5 col-sm-4 px-sm-2  col-md-4 px-md-3   col-lg-3 px-lg-3 mx-lg-0  col-xl-2 mx-xl-1   px-xl-0   text-center g-0 mb-3">

                                        <div className='group mb-3'>
                                            <div className="border_box">
                                                <div className="padding_box">
                                                    <i className='font-size2'><FontAwesomeIcon icon={faTimes} /></i>
                                                    <Link to={"/Product"}>
                                                    <img src={image.src} alt={`img-${index}`} />
                                                    </Link>                                                 
                                                </div>
                                            </div>
                                            <div className="price_cont align-items-center ">
                                                <p>{image.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}













                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Favorites;