import React from 'react';
import { Oval } from 'react-loader-spinner';
import './Loader.css'; 

const Loader = () => {
  return (
    <div className="loader-container">
      <Oval
        height={50}
        width={50}
        color="#4fa94d"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default Loader;